import React, { useState } from 'react';
import AdministratorHeader from './AdministratorHeader';

const SystemConfiguration = () => {
  const [activeTab, setActiveTab] = useState('systemConfig');

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <AdministratorHeader/>
      <h1>Admin Dashboard</h1>
      <div className="tabs">
        <button className={activeTab === 'systemConfig' ? 'active' : ''} onClick={() => handleTabChange('systemConfig')}>System Configuration</button>
        <button className={activeTab === 'featureIntegration' ? 'active' : ''} onClick={() => handleTabChange('featureIntegration')}>Feature Integration</button>
        <button className={activeTab === 'addService' ? 'active' : ''} onClick={() => handleTabChange('addService')}>Add New Service</button>
      </div>
      {activeTab === 'systemConfig' && <SystemConfigurationTab />}
      {activeTab === 'featureIntegration' && <FeatureIntegrationTab />}
      {activeTab === 'addService' && <AddServiceTab />}
      XAmpp Version 14
      node version 16.20.2
      Mysql version 6.2
      react 14.2
      Laravel 8.2


      
    </div>
  );
};

const SystemConfigurationTab = () => {
  // Implement system configuration settings here
  return (
    <div>
      <h2>System Configuration</h2>
      {/* Add forms and settings for system configuration */}
    </div>
  );
};

const FeatureIntegrationTab = () => {
  // Implement feature integration settings here
  return (
    <div>
      <h2>Feature Integration</h2>
      {/* Add forms and settings for feature integration */}
    </div>
  );
};

const AddServiceTab = () => {
  // State for new service data
  const [newService, setNewService] = useState({
    name: '',
    description: '',
    // Add more fields as needed
  });

  // Function to handle input changes for new service
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewService({ ...newService, [name]: value });
  };

  // Function to handle adding new service
  const addService = () => {
    // Implement logic to add new service (e.g., make API call)
    console.log('New service added:', newService);
    // Reset new service data
    setNewService({
      name: '',
      description: '',
      // Reset other fields as needed
    });
  };

  return (
    <div>
      <h2>Add New Service</h2>
      <form onSubmit={addService}>
        <div>
          <label>Name:</label>
          <input type="text" name="name" value={newService.name} onChange={handleInputChange} />
        </div>
        <div>
          <label>Description:</label>
          <textarea name="description" value={newService.description} onChange={handleInputChange} />
        </div>
        {/* Add more fields for other details of the new service */}
        <button type="submit">Add Service</button>
      </form>
    </div>
  );
};

export default SystemConfiguration;
