import React, { useState, useEffect } from 'react';
import HealthCareProviderHeader from './HealthCareProviderHeader';
import axios from 'axios';

const Eprescriptions = () => {
  const [prescriptions, setPrescriptions] = useState([]);

  useEffect(() => {
    const fetchPrescriptions = async () => {
      try {
        const response = await axios.get('https://sxg3378.uta.cloud/backend/prescription.php');
        setPrescriptions(response.data);
      } catch (error) {
        console.error('Error fetching prescriptions:', error);
      }
    };

    fetchPrescriptions();
  }, []);

  return (
    <>
      <HealthCareProviderHeader />
      <div className='container card' style={{textAlign:"left"}}>
        <h2 style={{ textAlign: 'center' }}>E-Prescriptions</h2>
        <div>
          <h3>Prescriptions</h3>
          <table>
            <thead>
              <tr>
                <th>Prescription ID</th>
                <th>Medicines</th>
              </tr>
            </thead>
            <tbody>
              {prescriptions.map((prescription) => (
                <tr key={prescription.PrescriptionID}>
                  <td>{prescription.PrescriptionID}</td>
                  <td>
                    <ul>
                      {prescription.medicines.map((medicine, index) => (
                        <li key={index}>
                          {medicine.MedicationName ? medicine.MedicationName : 'NA'} - {medicine.Dosage ? medicine.Dosage : 'NA'}
                        </li>
                      ))}
                    </ul>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Eprescriptions;
