import React, { useState, useEffect } from 'react';
import PharmacistHeader from './PharmacistHeader';

const MedicationDispensation = () => {
  const [prescriptions, setPrescriptions] = useState([]);

  const dispenseMedication = (id) => {
    fetch(`https://sxg3378.uta.cloud/backend/public/api/medication-dispensation/${id}`, {
      method: 'PUT',
    })
      .then(response => response.json())
      .then(data => {
        console.log('Medication dispensed successfully:', data);
        // Re-fetch prescription data
        fetchPrescriptions();
      })
      .catch(error => {
        console.error('Error dispensing medication:', error);
      });
  };
  
  const fetchPrescriptions = () => {
    fetch('https://sxg3378.uta.cloud/backend/public/api/medication-dispensation')
      .then(response => response.json())
      .then(data => {
        setPrescriptions(data);
      })
      .catch(error => {
        console.error('Error fetching prescription data:', error);
      });
  };
  
  useEffect(() => {
    fetchPrescriptions();
  }, []);
  

  return (
    <div>
      <PharmacistHeader/>
      <h1 style={{textAlign:"center",marginBottom:"25px",fontWeight:"800px"}}>Medication Dispensation</h1>
      <div className='container'>
        <h2>Prescriptions</h2>
        <table>
          <thead>
            <tr>
              <th>Doctor Name</th>
              <th>Medication</th>
              <th>Dosage</th>
              <th>Quantity</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {prescriptions.map(prescription => (
              <tr key={prescription.PrescriptionID}>
                <td>{prescription.user.Username}</td>
                <td>{prescription.medications.map(med => med.MedicationName).join(', ')}</td>
                <td>{prescription.medications.map(med => med.Dosage).join(', ')}</td>
                <td>{prescription.medications.length}</td>
                <td>{prescription.status === 1 ? 'Dispensed' : 'Pending'}</td>
                <td>
                  {prescription.status ===0  && (
                     <div className='col-4 button' style={{fontColor:"white",textDecoration:"none"}}>
                   
                    <a onClick={() => dispenseMedication(prescription.PrescriptionID)} >Dispense</a>
                    </div>
                  
                   )} 
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MedicationDispensation;
