import React from 'react'
import AdministratorHeader from './AdministratorHeader'
import {BrowserRouter as Router,Routes,Link,Route, useNavigate}  from 'react-router-dom';

function Administrator() {
  return (
    <><AdministratorHeader/>
    <div className='card'>
    <Link to='/healthcareprovidermanagement'>HealthcareProviderManagement</Link>
    </div>
    <div className='card'>
    <Link to='/systemconfiguration'>SystemConfiguration</Link>
    </div>
    {/* <div className='card'>
    <Link to='/dataoversight'>DataOversight</Link>
    </div> */}
    <div className='card'>
    <Link to='/reportgeneration'>ReportGeneration</Link>
    </div>
    <div className='card'>
    <Link to='/usermanagement'>UserManagement</Link>
    </div>
   
    
    </>
  )
}

export default Administrator