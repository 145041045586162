import React, { useState } from 'react';
import AdministratorHeader from './AdministratorHeader';

function ReportGeneration() {
  const [reportType, setReportType] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [generatedReport, setGeneratedReport] = useState(null);

  const generateReport = () => {
    const userActivityReport = [
      { userId: 1, action: 'Logged in', timestamp: '2024-04-23T08:00:00Z' },
      { userId: 2, action: 'Registered', timestamp: '2024-04-23T10:15:00Z' },
      { userId: 1, action: 'Logged out', timestamp: '2024-04-23T18:30:00Z' }
    ];

    const systemPerformanceReport = [
      { timestamp: '2024-04-23T08:00:00Z', responseTime: 150 },
      { timestamp: '2024-04-23T10:15:00Z', responseTime: 180 },
      { timestamp: '2024-04-23T18:30:00Z', responseTime: 200 }
    ];

    const healthTrendsReport = [
      { date: '2024-04-23', heartRate: 75, bloodPressure: '120/80' },
      { date: '2024-04-23', heartRate: 80, bloodPressure: '122/82' },
      { date: '2024-04-23', heartRate: 78, bloodPressure: '118/78' }
    ];

    switch (reportType) {
      case 'User Activity':
        setGeneratedReport(userActivityReport);
        break;
      case 'System Performance':
        setGeneratedReport(systemPerformanceReport);
        break;
      case 'Health Trends':
        setGeneratedReport(healthTrendsReport);
        break;
      default:
        setGeneratedReport(null);
    }
  };

  return (
    <>
      <AdministratorHeader />
      <div className='container'>
      <div className=''>
        <h1>Report Generation</h1>
        <div className='contact-form'>
          <label>
            Report Type:
            <select value={reportType} onChange={(e) => setReportType(e.target.value)}>
              <option value="">Select Report Type</option>
              <option value="User Activity">User Activity</option>
              <option value="System Performance">System Performance</option>
              <option value="Health Trends">Health Trends</option>
            </select>
          </label>
        </div>
        <div>
          <label>
            Start Date:
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </label>
        </div>
        <div>
          <label>
            End Date:
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </label>
        </div>
        <button onClick={generateReport}>Generate Report</button>
      </div>
      {generatedReport && (
        <div className='section health-record'>
          <h2>Generated Report</h2>
          <ul>
            {generatedReport.map((item, index) => (
              <li key={index}>{JSON.stringify(item)}</li>
            ))}
          </ul>
        </div>
      )}
      </div>
    </>
  );
}

export default ReportGeneration;
