import React, { useState } from 'react';
import HealthCareProviderHeader from './HealthCareProviderHeader';
import axios from 'axios';

const Eprescriptions = () => {
  const [prescriptions, setPrescriptions] = useState([]);
  const [medicine, setMedicine] = useState('');
  const [dosage, setDosage] = useState('');
  const [selectedPatient, setSelectedPatient] = localStorage.getItem('patientName');
  const [days, setDays] = useState('');
  const [medicineList, setMedicineList] = useState([]);

  const addMedicineToList = () => {
    if (medicine && dosage) {
      const newMedicine = { medicine, dosage };
      setMedicineList([...medicineList, newMedicine]);
      setMedicine('');
      setDosage('');
    } else {
      alert('Please fill in both medicine and dosage');
    }
  };

  const addPrescription = async () => {
    if (medicineList.length > 0 && selectedPatient && days) {
      try {
        console.log(selectedPatient);
        const response = await axios.post('https://sxg3378.uta.cloud/backend/public/api/prescriptions', {
          provider_id: localStorage.getItem('UserID'),
          user_id: localStorage.getItem('patientID'),
          medications: medicineList,
          days: days,
        });
        alert('Added');
        window.location.href="eprescription";
        setPrescriptions([...prescriptions, response.data]); 
        setMedicineList([]);
        setSelectedPatient('');
        setDays('');
      } catch (error) {
        console.error('Error adding prescription:', error);
        //alert('Error adding prescription. Please try again.');
      }
    } else {
      alert('Please add at least one medicine and fill in patient and days');
    }
  };

  const removeMedicineFromList = (index) => {
    const updatedMedicineList = [...medicineList];
    updatedMedicineList.splice(index, 1);
    setMedicineList(updatedMedicineList);
  };

  const removePrescription = async (prescriptionId) => {
    try {
      await axios.delete(`https://sxg3378.uta.cloud/backend/public/api/prescriptions/${prescriptionId}`);
      const updatedPrescriptions = prescriptions.filter((prescription) => prescription.id !== prescriptionId);
      setPrescriptions(updatedPrescriptions);
    } catch (error) {
      console.error('Error deleting prescription:', error);
      alert('Error deleting prescription. Please try again.');
    }
  };
  return (
    <>
      <HealthCareProviderHeader />
      <div className='container card' style={{textAlign:"left"}}>
        <h2 style={{ textAlign: 'center' }}>E-Prescriptions</h2>
        <div style={{ padding: '20px' }}>
          <h3 style={{ marginBottom: '10px' }}>Doctor's Prescription</h3>
          <div style={{ marginBottom: '10px' }} className='row-flex'>
            <div className='col-4'>
              <label>Medicine:</label>
              <input type="text" value={medicine} onChange={(e) => setMedicine(e.target.value)} style={{ marginRight: '10px' }} />
            </div>
            <div className='col-4'>
              <label>Dosage:</label>
              <input type="text" value={dosage} onChange={(e) => setDosage(e.target.value)} style={{ marginRight: '10px' }} />
            </div>
            <button onClick={addMedicineToList}className='button' style={{ padding: "5px", background: "green", border: "green", color: "white",height:"auto" }}>
              + Add Medicine
            </button>
          </div>
          <div>
            <ul>
              {medicineList.map((med, index) => (
                <li key={index}>
                  {med.medicine} - {med.dosage}
                  <button onClick={() => removeMedicineFromList(index)}>Remove</button>
                </li>
              ))}
            </ul>
          </div>
          
          <div style={{ marginBottom: '10px' }}>
            <label>Duration:</label>
            <select value={days} onChange={(e) => setDays(e.target.value)} style={{ marginRight: '10px', padding: "5px", width: "100%" }}>
              <option value="">Select Days</option>
              <option value="1 day">1 day</option>
              <option value="2 days">2 days</option>
              <option value="3 days">3 days</option>
              <option value="4 days">4 days</option>
              <option value="5 days">5 days</option>
            </select>
          </div>
          <button onClick={addPrescription} style={{ marginLeft: '10px' }} className='button'>Add Prescription</button>
        </div>

        <div>
          <h3>Prescriptions</h3>
          <table>
            <thead>
              <tr>
                <th>Medicine</th>
                <th>Dosage</th>
                <th>Patient</th>
                <th>Duration</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {prescriptions.map((prescription) => (
                <tr key={prescription.id}>
                  <td>
                    <ul>
                      {prescription.medicines.map((med, index) => (
                        <li key={index}>
                          {med.medicine} - {med.dosage}
                        </li>
                      ))}
                    </ul>
                  </td>
                  <td>{prescription.patient}</td>
                  <td>{prescription.days}</td>
                  <td>{prescription.date}</td>
                  <td><button onClick={() => removePrescription(prescription.id)} style={{ backgroundColor: "red" }} className='button'>Delete</button></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Eprescriptions;
