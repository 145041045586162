import React, { useState } from 'react';
import HealthAdministratorHeader from './HealthAdministratorHeader';

function StaffCoordination() {
  // Initial staff data (dummy data)
  const initialStaff = [
    { id: 1, name: 'Dr. John Doe', role: 'Healthcare Provider', accessLevel: 'Admin' },
    { id: 2, name: 'Dr. Jane Smith', role: 'Healthcare Provider', accessLevel: 'User' },
    { id: 3, name: 'Pharmacist Alice', role: 'Pharmacist', accessLevel: 'User' }
  ];

  // State to manage staff data
  const [staff, setStaff] = useState(initialStaff);
  const [newStaff, setNewStaff] = useState({ name: '', role: '', accessLevel: '' });
  const [editStaff, setEditStaff] = useState(null);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewStaff({ ...newStaff, [name]: value });
  };

  // Add a new staff member
  const addStaff = () => {
    setStaff([...staff, { id: staff.length + 1, ...newStaff }]);
    setNewStaff({ name: '', role: '', accessLevel: '' });
  };

  // Delete a staff member
  const deleteStaff = (id) => {
    setStaff(staff.filter(member => member.id !== id));
  };

  // Set staff member to edit mode
  const editStaffDetails = (member) => {
    setEditStaff(member);
    setNewStaff(member);
  };

  // Update staff member details
  const updateStaff = () => {
    setStaff(staff.map(member => (member.id === editStaff.id ? { ...member, ...newStaff } : member)));
    setEditStaff(null);
    setNewStaff({ name: '', role: '', accessLevel: '' });
  };

  return (
    <>
    <HealthAdministratorHeader/>
    <div className='container'>
      <h1>Staff Coordination</h1>
      {/* Add new staff member form */}
      <div>
        <input
          type="text"
          name="name"
          value={newStaff.name}
          onChange={handleInputChange}
          placeholder="Staff Name"
          style={{marginBottom:"15px",padding:"5px"}}
        />
        <input
          type="text"
          name="role"
          value={newStaff.role}
          onChange={handleInputChange}
          placeholder="Role"
          style={{marginBottom:"15px",padding:"5px"}}
        />
        <input
          type="text"
          name="accessLevel"
          value={newStaff.accessLevel}
          onChange={handleInputChange}
          placeholder="Access Level"
          style={{marginBottom:"0px",padding:"5px"}}
        />
        <div className='button' style={{marginTop:"15px"}}>
        <a onClick={editStaff ? updateStaff : addStaff}>
          {editStaff ? 'Update Staff' : 'Add Staff'}
        </a>
        </div>
      </div>
      {/* Display existing staff members */}
      <div style={{marginTop:"15px"}}>
        <h2>Existing Staff Members</h2>
        {staff.map(member => (
          <div key={member.id} style={{marginBottom:"25px"}}>
            <span>{member.name} - {member.role}</span>
            <span>Access Level: {member.accessLevel}</span>
            <button onClick={() => editStaffDetails(member)} style={{background: "blue",padding:"10px",borderRadius: "25px",border: "2px solid",
    letterSpacing: "1px",borderColor: "blue",marginLeft:"25px",marginRight:"25px",color:"white"}} >Edit</button>
            <button onClick={() => deleteStaff(member.id)} style={{background: "red",padding:"10px",borderRadius: "25px",border: "2px solid",
    letterSpacing: "1px",borderColor: "red",marginLeft:"0px",marginRight:"25px",color:"white"}}>Delete</button>
          </div>
        ))}
      </div>
    </div>
    </>
  );
}

export default StaffCoordination;