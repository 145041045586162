import React from 'react'
import {BrowserRouter as Router,Routes,Route, useNavigate}  from 'react-router-dom';
import Header from './Header';
import './Home.css';
import Features from './Features';
function Home() {
    
    
  return (
    <>
    <Header/>
    <section className="banner">
    <img src="https://img.freepik.com/premium-photo/doctor-hold-icon-health-electronic-medical-record-interface-digital-healthcare-network_34200-712.jpg?w=1380" alt="Banner Image" />
    <div className="banner-content">
      <div className="container">
        <h1>Welcome to SmartHealth Hub</h1>
        <p>A revolution in healthcare accessibility and management</p>
      </div>
    </div>
  </section>
  <Features/>
  <footer>
    <div className="container">
      <p>&copy; 2024 SmartHealth Hub. All rights reserved.</p>
    </div>
  </footer>
</>
  );
}

export default Home