import React, { useState,useEffect } from 'react';
import PatientHeader from './PatientHeader';

const SymptomChecker = () => {
  const [gender, setGender] = useState('');
  const [age, setAge] = useState('');
  const [medicalHistory, setMedicalHistory] = useState('');
  const [selectedSymptoms, setSelectedSymptoms] = useState([]);
  const [severity, setSeverity] = useState('');
  const [duration, setDuration] = useState('');
  const [potentialIssues, setPotentialIssues] = useState([]);

  const handleSymptomSelection = (symptom) => {
    if (!selectedSymptoms.includes(symptom)) {
      setSelectedSymptoms([...selectedSymptoms, symptom]);
    }
  };
  const symptomes=localStorage.getItem('symptoms');
  const handleSymptomRemoval = (symptom) => {
    setSelectedSymptoms(selectedSymptoms.filter((s) => s !== symptom));
  };

  const handleSubmitSymptoms = (e) => {
    e.preventDefault(); 
    
    const potentialIssuesData = [
      {
        name: 'Cough',
        description: 'A viral infection of your nose and throat.',
        treatment: 'Get plenty of rest, drink fluids, and use over-the-counter cold medications.',
      },
      {
        name: 'Sore throat, headaches, fatigue	Influenza',
        description: 'A contagious respiratory illness caused by influenza viruses.',
        treatment: 'Get plenty of rest, drink fluids, and take antiviral medications if prescribed.',
      },
      {
        name: 'Muscle Pain',
        description: 'Inflammation of the bronchial tubes, often caused by a viral infection.',
        treatment: 'Get plenty of rest, drink fluids, and use cough suppressants or expectorants as needed.',
      },
      {
        name: 'Gastritis',
        description: 'Inflammation of the lining of the stomach.',
        treatment: 'Avoid irritating foods, eat smaller, more frequent meals, and take antacids or medications prescribed by your doctor.',
      },
      {
        name: 'nausea',
        description: 'An infection in any part of your urinary system.',
        treatment: 'Drink plenty of water, take antibiotics prescribed by your doctor.',
      },
      {
        name: 'Migraine',
        description: 'A severe headache often accompanied by nausea, vomiting, and sensitivity to light and sound.',
        treatment: 'Rest in a quiet, dark room, take over-the-counter pain relievers, prescription medications.',
      },
      {
        name: 'Itchy, red and watery eyes',
        description: 'An allergic response causing sneezing, congestion, and itching.',
        treatment: 'Avoid triggers, use antihistamines, nasal sprays, and decongestants.',
      },
    ];

  //   const selectedIssues = potentialIssuesData.filter((issue) =>
  //   selectedSymptoms.includes(issue.name.toLowerCase())
  // );
  const lowerCaseSelectedSymptoms = selectedSymptoms.map(symptom => symptom.toLowerCase());
  const selectedIssues = potentialIssuesData.filter((issue) =>
    lowerCaseSelectedSymptoms.includes(issue.name.toLowerCase())
  );
  

  // setPotentialIssues(selectedIssues);
  setPotentialIssues(selectedIssues);
  const storedSymptoms = JSON.parse(localStorage.getItem('symptoms'));

  if (storedSymptoms) {
    // Update existing symptoms
    localStorage.setItem('symptoms', JSON.stringify([...storedSymptoms, ...selectedIssues]));
  } else {
    // Store new symptoms
    localStorage.setItem('symptoms', JSON.stringify(selectedIssues));
  }
  };
  useEffect(() => {
    // Fetch symptoms from localStorage on component mount
    const savedSymptoms = JSON.parse(localStorage.getItem('symptoms'));
    if (savedSymptoms) {
      setPotentialIssues(savedSymptoms);
    }
  }, []);  
  return (
    <>
      <PatientHeader />
      <div className="symptom-checker-container container card appointment-management card" style={{ textAlign: "left", marginBottom: "20px", padding: '50px' }}>
        <h2 style={{ textAlign: "center" }}>Symptom Checker</h2>
        <form onSubmit={handleSubmitSymptoms}> 
          <div>
            <h3>Patient Information:</h3>
            <div>
              <label>Gender:</label>
              <select value={gender} onChange={(e) => setGender(e.target.value)}>
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
            <div>
              <label>Age:</label>
              <input type="number" value={age} onChange={(e) => setAge(e.target.value)} />
            </div>
            <div>
              <label>Medical History:</label>
              <input type="text" value={medicalHistory} onChange={(e) => setMedicalHistory(e.target.value)} />
            </div>
          </div>

          <div>
            <h3>Select Symptoms:</h3>
            <div>
              <select onChange={(e) => handleSymptomSelection(e.target.value)} defaultValue="">
                <option value="" disabled>Select Symptom</option>
                <option value="Cough">Cough</option>
                <option value="Sore throat, headaches, fatigue	Influenza">Sore throat, headaches, fatigue	Influenza</option>
                <option value="Muscle Pain">Muscle Pain</option>
                <option value="Gastritis">Blood in the vomit</option>
                <option value="nausea">nausea</option>
                <option value="Migraine">Migraine</option>
                <option value="Itchy, red and watery eyes">Itchy, red and watery eyes</option>
              </select>
            </div>
          </div>

          {selectedSymptoms.length > 0 && (
            <div>
              <h6>Selected Symptoms:</h6>
              <ul>
                {selectedSymptoms.map((symptom, index) => (
                  <li key={index}>
                    {symptom}{' '}
                    <button onClick={() => handleSymptomRemoval(symptom)} type='button' style={{ backgroundColor: "#dc3545" }}>Remove</button> {/* Change type to button */}
                  </li>
                ))}
              </ul>
              <div>
                <label>Symptom Severity (1-10):</label>
                <input type="number" value={severity} onChange={(e) => setSeverity(e.target.value)} min="1" max="10" />
              </div>
              <div>
                <label>Symptom Duration (in days):</label>
                <input type="number" value={duration} onChange={(e) => setDuration(e.target.value)} min="0" />
              </div>
              <input type='submit' style={{padding:"10px"}} value={'Submit Symptoms'}></input> 
            </div>
          )}
        </form>

        {potentialIssues.length > 0 && (
          <div>
            <h3>Potential Health Issues:</h3>
            <ul>
              {potentialIssues.map((issue, index) => (
                <li key={index}>
                  <strong>{issue.name}</strong>
                  <p>Description: {issue.description}</p>
                  <p>Treatment: {issue.treatment}</p>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default SymptomChecker;
