import React, { useState, useEffect } from 'react';
import PatientHeader from './PatientHeader';

const PersonalHealthRecords = () => {
  const [formData, setFormData] = useState({
    medicalHistory: '',
    prescriptions: '',
    vitalSigns: '',
    exerciseData: ''
  });
  const [userPHR, setUserPHR] = useState(null);
  const user_id = localStorage.getItem('UserID');
 
  useEffect(() => {
    // Fetch user's PHR data
    const fetchPHR = async () => {
      try {
        
        const response = await fetch('https://sxg3378.uta.cloud/backend/public/api/phr?id='+user_id); // Replace '24' with the actual user id
        if (response.ok) {
          const data = await response.json();
          setUserPHR(data.user);
        } else {
          console.error('Failed to fetch user PHR data');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchPHR();
  }, []); // Empty dependency array ensures this effect runs only once, equivalent to componentDidMount


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.medicalHistory || !formData.prescriptions || !formData.vitalSigns || !formData.exerciseData) {
        alert('Please fill in all fields');
        return;
      }
      // Retrieve userId from localStorage
      const user_id = localStorage.getItem('UserID');
  
      const response = await fetch('https://sxg3378.uta.cloud/backend/public/api/storephr', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        // Include userId in the request body along with the form data
        body: JSON.stringify({ ...formData, user_id }),
      });
  
      if (response.ok) {
        // Handle successful response
        alert('Form data submitted successfully');
      } else {
        // Handle error response
        alert('Failed to submit form data');
      }
    } catch (error) {
      // Handle network error
      console.error('Error:', error);
    }
  };
  
  return (
    <>
      <PatientHeader />
      <div className='container'>
        <div className='row-flex'>
          <div className='card col-6' style={{ textAlign: "left" }}>
            <h2 style={{ textAlign: "center" }}>Personal Health Records (PHR)</h2>
            <form onSubmit={handleSubmit}>
              <div>
                <label htmlFor="medicalHistory">Medical History:</label>
                <input
                  type="text"
                  name="medicalHistory"
                  id="medicalHistory"
                  value={formData.medicalHistory}
                  onChange={handleChange}
                  placeholder="Add medical history"
                />
              </div>

              <div>
                <label htmlFor="prescriptions">Prescriptions:</label>
                <input
                  type="text"
                  name="prescriptions"
                  id="prescriptions"
                  value={formData.prescriptions}
                  onChange={handleChange}
                  placeholder="Add prescription"
                />
              </div>

              <div>
                <label htmlFor="vitalSigns">Vital Signs:</label>
                <input
                  type="text"
                  name="vitalSigns"
                  id="vitalSigns"
                  value={formData.vitalSigns}
                  onChange={handleChange}
                  placeholder="Add vital signs data"
                />
              </div>

              <div>
                <label htmlFor="exerciseData">Exercise Data:</label>
                <input
                  type="text"
                  name="exerciseData"
                  id="exerciseData"
                  value={formData.exerciseData}
                  onChange={handleChange}
                  placeholder="Add exercise data"
                />
              </div>

              <button type="submit">Save Changes</button>
            </form>
          </div>

          <div className='col-6'>
            <div style={{ textAlign: "center" }}>
              <img src="https://assets-global.website-files.com/60ab7d80cd732355d467869f/6238989142bb417a9f60c08a_thumbnail.jpeg" alt="Health Records" style={{ maxWidth: "100%" }} />
            </div>
          </div>
        </div>
      </div>
      {/* Display user's PHR data */}
      {userPHR && (
        <div className='container'>
          <h2>User's Personal Health Records</h2>
          <table>
            <thead>
              <tr>
                <th>Medical History</th>
                <th>Prescriptions</th>
                <th>Vital Signs</th>
                <th>Exercise Data</th>
              </tr>
            </thead>
            <tbody>
              {userPHR.map(record => (
                <tr key={record.id}>
                  <td>{record.medicalHistory}</td>
                  <td>{record.prescriptions}</td>
                  <td>{record.vitalSigns}</td>
                  <td>{record.exerciseData}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default PersonalHealthRecords;
