import React from 'react'
import {BrowserRouter as Router,Routes,Route,Link, useNavigate}  from 'react-router-dom';
import PharmacistHeader from './PharmacistHeader';

function Pharmacist() {
  return (
    <><PharmacistHeader/>
    <div className='card'>
    <Link to='/medicationdispensation'>MedicationDispensation</Link>
    </div>
    
    <div className='card'>
    <Link to='/communication'>Communication</Link>
    </div>
    <div className='card'>
    <Link to='/medicationhistory'>MedicationHistory</Link>
    </div>
   
    
    </>
  )
}

export default Pharmacist