import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './Components/Home';
import AboutUs from './Components/AboutUs';
import Login from './Components/Login';
import Register from './Components/Register';
import Contact from './Components/Contact';
import Service from './Components/Service';
import HealthCareDashboard from './Components/HealthCareDashboard';
import AdminDashboard from './Components/AdminDashboard';
import Pharmacy from './Components/Pharmacy';
import HealthCareAdmin from './Components/HealthCareAdmin';
import Patients from './Components/PatientsComponent/Patients';
import Administrator from './Components/AdministratorComponent/Administrator';
import HealthAdministrator from './Components/HealthAdministratorComponent/HealthAdministrator';
import Pharmacist from './Components/PharmacistComponent/Pharmacist';
import SymptomChecker from './Components/PatientsComponent/SymptomChecker';
import PersonalHealthRecords from './Components/PatientsComponent/PersonalHealthRecords';
import AppointmentManagement from './Components/PatientsComponent/AppointmentManagement';
import CommunityInteraction from './Components/PatientsComponent/CommunityInteraction';
import PrescriptionManagement from './Components/PatientsComponent/PrescriptionManagement';
import Eprescriptions from './Components/HealthCareProvidersComponent/Eprescriptions';
import HealthAppointmentManagement from './Components/HealthCareProvidersComponent/HealthAppointmentManagement';
import Phr from './Components/HealthCareProvidersComponent/Phr';
import SecureMessaging from './Components/HealthCareProvidersComponent/SecureMessaging';
import ProfessionalCollaboration from './Components/HealthCareProvidersComponent/ProfessionalCollaboration';
import AnalyticsDashboard from './Components/HealthCareProvidersComponent/AnalyticsDashboard';
import HealthCareProviders from './Components/HealthCareProvidersComponent/HealthCareProviders';
import UserManagement from './Components/AdministratorComponent/UserManagement';
import HealthcareProviderManagement from './Components/AdministratorComponent/HealthcareProviderManagement';
import SystemConfiguration from './Components/AdministratorComponent/SystemConfiguration';
import DataOversight from './Components/AdministratorComponent/DataOversight';
import ReportGeneration from './Components/AdministratorComponent/ReportGeneration';
import FacilityMangement from './Components/HealthAdministratorComponent/FacilityMangement';
import StaffCoordination from './Components/HealthAdministratorComponent/StaffCoordination';
import ComplianceOversight from './Components/HealthAdministratorComponent/ComplianceOversight';
import IncidentResponse from './Components/HealthAdministratorComponent/IncidentResponse';
import MedicationHistory from './Components/PharmacistComponent/MedicationHistory';
import Communication from './Components/PharmacistComponent/Communication';
import MedicationDispensation from './Components/PharmacistComponent/MedicationDispensation';
import MedicationReminders from './Components/PatientsComponent/MedicationReminders';
import VerifyEmailPage from './Components/VerifyEmailPage';
import ChatPage from './Components/ChatPage';
import Eprescription from './Components/HealthCareProvidersComponent/Eprescription';

function App() {
  const [userEmail, setUserEmail] = useState('');

  useEffect(() => {
    const userEmail = localStorage.getItem('email');
    setUserEmail(userEmail);
  }, []);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login setUserEmail={setUserEmail} />} />
          <Route path="/register" element={<Register setUserEmail={setUserEmail} />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/service" element={<Service />} />
          <Route path="/chat" element={<ChatPage />} />

          {/* Check if user is authenticated */}
          {userEmail ? (
            <>
              <Route path="/patients" element={<Patients />} />
              <Route path="/symptomchecker" element={<SymptomChecker />} />
              <Route path="/medicationreminder" element={<MedicationReminders />} />
              <Route path="/personalhealthrecords" element={<PersonalHealthRecords />} />
              <Route path="/appointmentmanagement" element={<AppointmentManagement />} />
              <Route path="/communityinteraction" element={<CommunityInteraction />} />
              <Route path="/prescriptionmanagement" element={<PrescriptionManagement />} />
              <Route path="/healthcareproviders" element={<HealthCareProviders />} />
              <Route path="/eprescriptions" element={<Eprescriptions />} />
              <Route path="/eprescription" element={<Eprescription />} />
              
              <Route path="/healthappointmentmanagement" element={<HealthAppointmentManagement />} />
              <Route path="/phr" element={<Phr />} />
              <Route path="/securemessaging" element={<SecureMessaging />} />
              <Route path="/professionalcollab" element={<ProfessionalCollaboration />} />
              <Route path="/analytics" element={<AnalyticsDashboard />} />
              <Route path="/administrator" element={<Administrator />} />
              <Route path="/usermanagement" element={<UserManagement />} />
              <Route path="/healthcareprovidermanagement" element={<HealthcareProviderManagement />} />
              <Route path="/systemconfiguration" element={<SystemConfiguration />} />
              <Route path="/dataoversight" element={<DataOversight />} />
              <Route path="/reportgeneration" element={<ReportGeneration />} />
              <Route path="/healthadministrator" element={<HealthAdministrator />} />
              <Route path="/facility" element={<FacilityMangement />} />
              <Route path="/staff" element={<StaffCoordination />} />
              <Route path="/compliance" element={<ComplianceOversight />} />
              <Route path="/incidentresponse" element={<IncidentResponse />} />
              <Route path="/pharmacist" element={<Pharmacist />} />
              <Route path="/medicationdispensation" element={<MedicationDispensation />} />
              <Route path="/medicationhistory" element={<MedicationHistory />} />
              <Route path="/communication" element={<Communication />} />
            </>
          ) : (
            // Redirect unauthenticated users to login page
            <Route element={<Navigate to="/login" />} />
          )}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
