import React, { useState, useEffect } from 'react';
import HealthCareProviderHeader from './HealthCareProviderHeader';

const Phr = () => {
  const [healthRecords, setHealthRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchHealthRecords();
  }, []);

  const fetchHealthRecords = async () => {
    try {
      const response = await fetch('https://sxg3378.uta.cloud/backend/public/api/allphr');
      const data = await response.json();
      if (data && data.user) {
        setHealthRecords(data.user);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching health records:', error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <HealthCareProviderHeader />
      <div className='container card' style={{ textAlign: "left" }}>
        <div className="health-records-container">
          <h2>Access to Patient Health Records</h2>
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            <table className="health-records-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Medical History</th>
                  <th>Prescriptions</th>
                  <th>Vital Signs</th>
                  <th>Exercise Data</th>
                </tr>
              </thead>
              <tbody>
                {healthRecords.map(record => (
                  <tr key={record.id}>
                    <td>{record.id}</td>
                    <td>{record.medicalHistory}</td>
                    <td>{record.prescriptions}</td>
                    <td>{record.vitalSigns}</td>
                    <td>{record.exerciseData}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default Phr;
