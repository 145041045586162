import React, { useState, useEffect } from 'react';
import AdministratorHeader from './AdministratorHeader';

const UserManagement = () => {
  // State for storing user data
  const [users, setUsers] = useState([]);
  
  // State for form data
  const [formData, setFormData] = useState({
    id: null,
    Username: '',
    Email: '',
    Role: '',
    Address: '',
    Phone: '',
    Password:'',
    DateofBirth:'1990-05-05',
    Status:'Active'
  });

  // Function to handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Function to fetch users data from the API
  const fetchUsers = async () => {
    try {
      const response = await fetch('https://sxg3378.uta.cloud/backend/public/api/users');
      const data = await response.json();
      setUsers(data.user);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      
      if (formData.id !== null) {
        // If ID exists, update existing user
        await fetch(`https://sxg3378.uta.cloud/backend/public/api/users/${formData.id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        });
        // Update the user list after successful update
        fetchUsers();
      } else {
        const requiredFields = ['Username', 'Email', 'Role', 'Address', 'Phone', 'Password'];

  // Check if any required fields are empty
  for (const field of requiredFields) {
    if (!formData[field]) {
      alert(`Please fill in ${field}`);
      return;
    }
  }
        // Add the new user to the users list
        await fetch('https://sxg3378.uta.cloud/backend/public/api/users', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        });
        // Update the user list after successful creation
        fetchUsers();
      }
      // Reset form data
      setFormData({
        id: null,
        Username: '',
        Email: '',
        Role: '',
        Address: '',
        Phone: '',
        Password:'',
        DateofBirth:'1990-05-05',
        Status:'Active'
      });
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  // Function to handle user deletion
  const deleteUser = async (id) => {
    try {
      await fetch(`https://sxg3378.uta.cloud/backend/public/api/users/${id}`, {
        method: 'DELETE'
      });
      // Update the user list after successful deletion
      fetchUsers();
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  // Function to edit user
  const editUser = (user) => {
    setFormData({
      id: user.UserID,
      Username: user.Username,
      Email: user.Email,
      Role: user.Role,
      Address: user.Address,
      Phone: user.Phone,
      Password: user.Password
    });
  };

  // Fetch users data when component mounts
  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div>
      <AdministratorHeader/>
      <div className='container'>
      <h2 style={{marginTop:"10px",textAlign:"center"}}>User Management</h2>
      <div className='row-flex'>
        <div className='col-6'>
      <form onSubmit={handleSubmit} className='card' style={{marginLeft:"auto",marginRight:"auto",display:"block",textAlign:"left"}}>
        <div>
          <label>User Name:</label>
          <input type="text" name="Username" value={formData.Username} onChange={handleChange} />
        </div>
        <div>
          <label>Email:</label>
          <input type="Email" name="Email" value={formData.Email} onChange={handleChange} />
        </div>
        <div>
          <label>Select Role:</label><br/>
          <select name="Role" value={formData.Role} onChange={handleChange} style={{border: "1px solid #e0e3eb",
    height: "45px",
    color: "#18214d",
    fontSize: "14px",
    borderRadius:" 5px",
    width: "100%"}}>
            <option value="">Select Role</option>
            <option value="Patient">Patient</option>
            <option value="Healthcare Provider">Healthcare Provider</option>
            <option value="Health Administrator">Healthcare Admin</option>
            <option value="Pharmacist">Pharmacist</option>
          </select>
        </div>
        <div>
          <label>Address:</label>
          <input type="text" name="Address" value={formData.Address} onChange={handleChange} />
        </div>
        <div>
          <label>Phone:</label><br/>
          <input type="text" name="Phone" value={formData.Phone} onChange={handleChange} />
        </div>
        <div>
          <label>Password:</label><br/>
          <input type="text" name="Password" value={formData.Password} onChange={handleChange} />
        </div>
        <button type="submit" style={{width:"100%",marginTop:"10px"}}>{formData.id !== null ? 'Update' : 'Submit'}</button>
      </form>
      </div>
      <div className='col-6'>
        <img src="https://img.freepik.com/free-vector/usability-testing-concept-illustration_114360-1878.jpg?w=740&t=st=1710007487~exp=1710008087~hmac=fb6e1c7cdaf86429ae8c2125dadf3bd05c378cb1d81649824352a92667061bfb" style={{width:'100%'}}/>
      </div>
      </div>
      <div className='card'>
        <h3>User List</h3>
        <table>
          <thead>
            <tr>
              <th>User Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Address</th>
              <th>Phone</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td>{user.Username}</td>
                <td>{user.Email}</td>
                <td>{user.Role}</td>
                <td>{user.Address}</td>
                <td>{user.Phone}</td>
                <td>
                  <button onClick={() => editUser(user)} style={editButtonStyle}>Edit</button>
                  <button onClick={() => deleteUser(user.UserID)} style={deleteButtonStyle}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
        </div>
      </div>
  );
};

// Button styles
const submitButtonStyle = {
  backgroundColor: '#4CAF50',
  color: 'white',
  padding: '10px 20px',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  margin: '5px'
};

const editButtonStyle = {
  backgroundColor: '#008CBA',
  color: 'white',
  padding: '5px 10px',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  marginRight: '5px'
};

const deleteButtonStyle = {
  backgroundColor: '#f44336',
  color: 'white',
  padding: '5px 10px',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer'
};

export default UserManagement;
