import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HealthAdministratorHeader from './HealthAdministratorHeader';

function ComplianceOversight() {
  const [complianceIssues, setComplianceIssues] = useState([]);
  const [newIssue, setNewIssue] = useState('');

  useEffect(() => {
    fetchComplianceIssues();
  }, []);

  const fetchComplianceIssues = async () => {
    try {
      const response = await axios.get('https://sxg3378.uta.cloud/backend/messages.php?Issue=1');
      setComplianceIssues(response.data.message);
    } catch (error) {
      console.error('Error fetching compliance issues:', error);
    }
  };

  const addComplianceIssue = async () => {
    try {
      if (newIssue.trim() !== '') {
        // Create a new FormData object
        const formData = new FormData();
        // Append the newIssue to the FormData object
        formData.append('newIssue', newIssue.trim());
  
        // Make a POST request with form payload
        const response = await axios.post('https://sxg3378.uta.cloud/backend/messages.php', formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded' // Set the appropriate content type
          }
        });
        fetchComplianceIssues();
        // Assuming the API returns a status indicating success
        if (response.data.status === 'success') {
          setNewIssue('');
          fetchComplianceIssues();
        } else {
          console.error('Error adding compliance issue:', response.data.message);
        }
      }
    } catch (error) {
      console.error('Error adding compliance issue:', error);
    }
  };
  
  const deleteComplianceIssue = async (id) => {
    try {
      const formData = new FormData();
      // Append the newIssue to the FormData object
      formData.append('deleteIssueId', id);

      const response = await axios.post('https://sxg3378.uta.cloud/backend/messages.php', formData);
      if (response.data.status === 'success') {
        fetchComplianceIssues();
      } else {
        console.error('Error deleting compliance issue:', response.data.message);
      }
    } catch (error) {
      console.error('Error deleting compliance issue:', error);
    }
  };

  return (
    <><HealthAdministratorHeader/>
    <div className='container'>

      <h2>Compliance Oversight</h2>
      <div style={{marginBottom:"5px"}}>
        <input
          type="text"
          value={newIssue}
          onChange={(e) => setNewIssue(e.target.value)}
          placeholder="Enter new compliance issue"
          style={{padding:"10px"}}
        />
        <div className='button' style={{marginTop:"5px"}}>
        <a onClick={addComplianceIssue}>Add Issue</a>
        </div>
      </div>
      <div style={{marginTop:"5px"}}>
        <h3>Existing Compliance Issues</h3>
        <tbody>
          {complianceIssues && complianceIssues.map((issue) => (
            <tr key={issue.id} style={{}}>
              <td>{issue.issue_description}</td>
              <td>
              <a  className='button' onClick={() => deleteComplianceIssue(issue.id)} style={{background:"red",borderColor:"red"}}>Delete</a>
              
            </td></tr>
          ))}
        </tbody>
      </div>
    </div></>
  );
}

export default ComplianceOversight;
