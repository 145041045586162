import React, { useState } from 'react';
import PharmacistHeader from './PharmacistHeader';

function Communication() {
  // State to manage messages
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [selectedConcern, setSelectedConcern] = useState('');

  // Sample patient concerns (dummy data)
  const patientConcerns = [
    'Medication usage',
    'Potential side effects',
    'Allergic reactions',
    'Dosage instructions',
    'General inquiries',
  ];

  // Function to send a new message
  const sendMessage = () => {
    if (newMessage.trim() !== '') {
      setMessages([...messages, { text: newMessage.trim(), sender: 'Pharmacist', timestamp: new Date() }]);
      setNewMessage('');
    }
  };

  const handleConcernSelection = (concern) => {
    setSelectedConcern(concern);
    setNewMessage(`Regarding ${concern}: `);
  };

  return (
    <>
      <PharmacistHeader />
      <div className='container'>
        <h1 style={{textAlign:"center",marginBottom:"25px"}}>Communication</h1>
        <div>
          <h2>Messages</h2>
          <div style={{ border: '1px solid #ccc', padding: '10px', maxHeight: '300px', overflowY: 'scroll' }}>
            {messages.map((message, index) => (
              <div key={index}>
                <strong>{message.sender}: </strong>{message.text}
                <div style={{ fontSize: '0.8em', color: '#888' }}>{message.timestamp.toLocaleString()}</div>
              </div>
            ))}
          </div>
          {/* Patient concerns list */}
          <div style={{ marginTop: '20px' }}>
            <h3>Select Patient Concern:</h3>
            <ul>
              {patientConcerns.map((concern, index) => (
                <li key={index} style={{ cursor: 'pointer' }} onClick={() => handleConcernSelection(concern)}>
                  {concern}
                </li>
              ))}
            </ul>
          </div>
          <textarea
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type your message here..."
            style={{ width: '100%', marginTop: '10px' }}
          />
          <button onClick={sendMessage} style={{ marginTop: '10px' }}>Send Message</button>
        </div>
      </div>
    </>
  );
}

export default Communication;
