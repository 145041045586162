import React, { useState } from 'react';
import Header from './Header';
import { useNavigate } from 'react-router-dom';

function Register() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        address: '',
        phone: '',
        dob: ''
    });
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleRegister = async (e) => {
        e.preventDefault();
        try {
            // Call backend API to register user
            const response = await fetch('https://sxg3378.uta.cloud/backend/public/api/register', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });
            const data = await response.json();
            if (data.status=='success') {
                setMessage(data.message);
                alert('Mail has been sent to verify email');
                 navigate('/login');
            } else {
                setError(data.message || 'Registration failed. Please try again.');
            }
        } catch (error) {
            console.error('Error:', error);
            setError('Registration failed. Please try again.');
        }
    };

    return (
        <>
            <Header />
            <div className='container' style={{ marginTop: "25px", maxWidth: "500px", marginLeft: "auto", marginRight: "auto" }}>
                <div className='register card' style={{ textAlign: "left" }}>
                    <img src='36.png' style={{ width: "100%", height: "auto" }} alt="Register" />
                    <h3 style={{ textAlign: "center" }}>Register</h3>
                    {message && <p style={{ border: "1px solid", color: "green" }}>{message}</p>}
                    {error && <p style={{ border: "1px solid", color: "red" }}>{error}</p>}

                    <form onSubmit={handleRegister}>
                        <label>Name:</label>
                        <input type='text' name="username" onChange={handleChange} required />
                        <label>Email:</label>
                        <input type='email' name="email" onChange={handleChange} required />
                        <label>Password:</label>
                        <input type='password' name="password" onChange={handleChange} required />
                        <label>Address:</label>
                        <input type='text' name="address" onChange={handleChange} required />
                        <label>Mobile:</label>
                        <input type='text' name='phone' onChange={handleChange} required />
                        <label>Date of Birth:</label>
                        <input type='date' name='dob' onChange={handleChange} required />
                        <button type="submit" style={{ width: "100%", marginTop: "25px", borderRadius: "0px" }}>Register</button>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Register;
