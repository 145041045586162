import React, { useState } from 'react';
import PatientHeader from './PatientHeader';

const MedicationReminders = () => {
  // State for medications and reminders
  const [medications, setMedications] = useState([]);
  const [reminders, setReminders] = useState([]);

  const addMedication = (medicationName) => {
    if (!medications.find((medication) => medication.name === medicationName)) {
      const newMedication = {
        name: medicationName,
        reminders: [],
      };
      setMedications([...medications, newMedication]);
    }
  };

  // Function to remove a medication
  const removeMedication = (medicationName) => {
    setMedications(medications.filter((medication) => medication.name !== medicationName));
  };

  // Function to add a reminder for a specific medication
  const addReminder = (medicationName, reminderTime) => {
    const updatedMedications = medications.map((medication) => {
      if (medication.name === medicationName) {
        return {
          ...medication,
          reminders: [...medication.reminders, reminderTime],
        };
      }
      return medication;
    });
    setMedications(updatedMedications);
  };

  // Function to remove a reminder for a specific medication
  const removeReminder = (medicationName, reminderTime) => {
    const updatedMedications = medications.map((medication) => {
      if (medication.name === medicationName) {
        return {
          ...medication,
          reminders: medication.reminders.filter((reminder) => reminder !== reminderTime),
        };
      }
      return medication;
    });
    setMedications(updatedMedications);
  };

  // Function to schedule medication reminders
  const scheduleReminders = () => {
    const allReminders = medications.reduce((acc, medication) => {
      return [...acc, ...medication.reminders];
    }, []);

    setReminders(allReminders);
  };
  return (
    <>
    <PatientHeader/>
    <div className="medication-reminders-container"> {/* Container for styling */}
      <h2>Medication Reminders</h2>
      <div className="add-medication-section">
        <h3>Add Medication:</h3>
        <button onClick={() => addMedication('Aspirin')}>Add Aspirin</button>
        <button onClick={() => addMedication('Paracetamol')}>Add Paracetamol</button>
        {/* Add more medication buttons as needed */}
      </div>

      <div className="medications-section">
        <h3>Medications:</h3>
        <ul>
          {medications.map((medication, index) => (
            <li key={index}>
              {medication.name}
              <button onClick={() => removeMedication(medication.name)}>Remove</button>
              <div className="reminders-section">
                <h4>Reminders:</h4>
                <ul>
                  {medication.reminders.map((reminder, index) => (
                    <li key={index}>
                      {reminder}
                      <button onClick={() => removeReminder(medication.name, reminder)}>
                        Remove
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <div className="schedule-reminders-section">
        <h3>Schedule Reminders:</h3>
        <button onClick={scheduleReminders}>Schedule Reminders</button>
      </div>

      {reminders.length > 0 && (
        <div className="reminders-list-section">
          <h3>Reminders:</h3>
          <ul>
            {reminders.map((reminder, index) => (
              <li key={index}>{reminder}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
</>
  );
};

export default MedicationReminders;
