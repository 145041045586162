import React, { useState, useEffect } from 'react';
import HealthAdministratorHeader from './HealthAdministratorHeader';

function IncidentResponse() {
  // State to manage incident data
  const [incidents, setIncidents] = useState([]);
  const [newIncident, setNewIncident] = useState({
    type: '',
    description: '',
    reportedBy: '',
    status: 'Open'
  });

  // Effect to fetch incidents from local storage when component mounts
  useEffect(() => {
    const storedIncidents = localStorage.getItem('incidents');
    if (storedIncidents) {
      setIncidents(JSON.parse(storedIncidents));
    }
  }, []);

  // Function to handle input changes for new incident
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewIncident({ ...newIncident, [name]: value });
  };

  // Function to add a new incident
  const addIncident = () => {
    if (newIncident.type.trim() !== '' && newIncident.description.trim() !== '' && newIncident.reportedBy.trim() !== '') {
      // Create a new incident object with a unique ID
      const newIncidentWithId = { ...newIncident, id: incidents.length + 1 };

      // Merge the new incident with the existing incidents
      const updatedIncidents = [...incidents, newIncidentWithId];

      // Update the incidents state
      setIncidents(updatedIncidents);

      // Update the local storage with the updated incidents array
      localStorage.setItem('incidents', JSON.stringify(updatedIncidents));

      // Clear the new incident form fields
      setNewIncident({
        type: '',
        description: '',
        reportedBy: '',
        status: 'Open'
      });
    }
  };

  // Function to update the status of an incident
  const updateIncidentStatus = (id, newStatus) => {
    const updatedIncidents = incidents.map(incident =>
      incident.id === id ? { ...incident, status: newStatus } : incident
    );
    setIncidents(updatedIncidents);
    localStorage.setItem('incidents', JSON.stringify(updatedIncidents));
  };

  return (
    <>
      <HealthAdministratorHeader />
      <div className='container'>
        <h1>Incident Response</h1>
        {/* Add new incident form */}
        <div>
          <input
            type="text"
            name="type"
            value={newIncident.type}
            onChange={handleInputChange}
            placeholder="Incident Type"
            style={{ marginBottom: "5px" }}
          />
          <input
            type="text"
            name="description"
            value={newIncident.description}
            onChange={handleInputChange}
            placeholder="Description"
            style={{ marginBottom: "5px" }}
          />
          <input
            type="text"
            name="reportedBy"
            value={newIncident.reportedBy}
            onChange={handleInputChange}
            placeholder="Reported By"
            style={{ marginBottom: "5px" }}
          />
          <button onClick={addIncident} className='button'>Add Incident</button>
        </div>
        {/* Display existing incidents */}
        <div>
          <h2>Existing Incidents</h2>
          {incidents.length === 0 ? (
            <p>No incidents found.</p>
          ) : (
            <ul>
              {incidents.map(incident => (
                <li key={incident.id}>
                  <strong>Type:</strong> {incident.type}<br />
                  <strong>Description:</strong> {incident.description}<br />
                  <strong>Reported By:</strong> {incident.reportedBy}<br />
                  <strong>Status:</strong> {incident.status}<br />
                  {incident.status === 'Open' && (
                    <button onClick={() => updateIncidentStatus(incident.id, 'Resolved')} className='button' style={{ background: "blue" }}>Resolve</button>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </>
  );
}

export default IncidentResponse;
