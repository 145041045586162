import React from 'react'
import {BrowserRouter as Router,Routes,Route, useNavigate}  from 'react-router-dom';
import Header from './Header';

function Service() {
    const navigate=useNavigate();

    const handleBackClick=()=>{
        navigate('/')
    }
    const services = [
      { name: 'X-Ray Lab', description: 'State-of-the-art X-ray services.'  },
      { name: 'MRI Lab', description: 'Advanced MRI scanning facilities.' },
      { name: 'Pharmacy', description: 'A wide range of medications available.' },
      { name: 'Laboratory', description: 'Accurate and reliable lab tests.' },
      { name: 'Emergency Care', description: '24/7 emergency medical services.' },
      { name: 'Physical Therapy', description: 'Professional physical therapy services.' },
    ];
  return (
    <><Header/>
    <div className="service-page">
      <div className="centered-content">

        <div><h3>Services</h3>
        {/* <h1>Smart Health  Hub Services</h1> */}
        <p>Empowering Health, Enriching Lives
</p></div>
<div className="top-image" >
        <img src={"https://www.techlene.com/assets/images/industry/healthcare-management-system.jpg"} alt="Service Image" />
      </div>
    
        <div className="services">
          {services.map((service, index) => (
            <div key={index} className="service">
              <h3>{service.name}</h3>
              <p>{service.description}</p>
            </div>
          ))}
        </div>
      </div>
      
    </div>
    <footer className="servicefooter">
        <p>&copy; 2022 Health Management System. All rights reserved.</p>
      </footer>
    </>
  )
}

export default Service