import React from 'react'
import {BrowserRouter as Router,Routes,Route,Link, useNavigate}  from 'react-router-dom';

function AdministratorHeader() {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('email');
    localStorage.removeItem('role');
    navigate('/');
  };
  return (
    <header>
      <div className="" style={{display:"flex",margin: "0 auto",padding: "0 20px"}}>
      
                <div className="logo"><a href="/"><img src='logo.jpg' style={{width:"25%"}}></img></a></div>
              <nav id="nav">
          <ul>
          <li> <Link to='/'>Home</Link> </li>
            
            <li><Link to='/administrator'>Dashboard</Link></li>
            <li><Link to='/chat'>Chat</Link></li>
            
             <li><a onClick={handleLogout} href="#">Logout</a></li>
     
               </ul>
        </nav>
        <div className="hamburger" id="hamburger">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <div className="close" id="close">
          &times;
        </div>
      </div>
    </header>

    )
}

export default AdministratorHeader