import React, { useState, useEffect } from 'react';
import './ChatPage.css'; // Import the CSS file for styling
import PatientHeader from './PatientsComponent/PatientHeader';
import AdministratorHeader from './AdministratorComponent/AdministratorHeader';
import PharmacistHeader from './PharmacistComponent/PharmacistHeader';
import HealthAdministratorHeader from './HealthAdministratorComponent/HealthAdministratorHeader';
import HealthCareProviderHeader from './HealthCareProvidersComponent/HealthCareProviderHeader';

const ChatPage = () => {
  const [selectedUser, setSelectedUser] = useState(null); // State to store the selected user
  const [messages, setMessages] = useState([]); // State to store messages
  const [newMessage, setNewMessage] = useState(''); // State to store new message
  const [users, setUsers] = useState([]); // State to store user list
  const currentUserRole = localStorage.getItem('role');
  const [messages1, setMessages1] = useState([]); // State to store messages

  useEffect(() => {
    // Fetch user data from the API
    fetch('https://sxg3378.uta.cloud/backend/public/api/users')
      .then(response => response.json())
      .then(data => {
        console.log('Fetched users:', data.user);
        const currentUserId = parseInt(localStorage.getItem('UserID'), 10);
console.log('Current user ID:', currentUserId);
        
        // Filter out the current user from the user list
        const filteredUsers = data.user.filter(user => {
          console.log('User ID:', user.UserID);
          console.log('Comparison result:', user.UserID !== currentUserId);
          return user.UserID !== currentUserId;
        });
  
        console.log('Filtered users:', filteredUsers);
        
        setUsers(filteredUsers);
      })
      .catch(error => {
        console.error('Error fetching user data:', error);
      });
  }, []); // Run the effect only once on component mount
  

const fetchMessages = (senderId, receiverId) => {
    
    fetch(`https://sxg3378.uta.cloud/backend/messages.php?senderId=${senderId}&receiverId=${receiverId}`)
      .then(response => response.json())
      .then(data => {
        setMessages(data);
      })
      .catch(error => {
        console.error('Error fetching messages:', error);
      });
  };
  useEffect(() => {
    const fetchNewMessages = async () => {
      try {
        const response = await fetch(`https://sxg3378.uta.cloud/backend/messages.php?new=new&sender_Id=${localStorage.getItem('UserID')}`);
        if (!response.ok) {
          throw new Error('Failed to fetch new messages');
        }
        const data = await response.json();
        setMessages1(data); // Update messages state with new messages
      } catch (error) {
        console.error('Error fetching new messages:', error);
      }
    };
  
    // Fetch new messages when the selected user changes
    // if (selectedUser) {
    //   fetchNewMessages();
    // }
    const intervalId = setInterval(fetchNewMessages, 10000);

    // Clear the interval when the component unmounts or when selectedUser changes
    return () => clearInterval(intervalId);
  }, []);
  
  const currentUserId=localStorage.getItem('UserID');
  useEffect(() => {
    // Fetch user data from the API
    fetch('https://sxg3378.uta.cloud/backend/public/api/users')
      .then(response => response.json())
      .then(data => {
        console.log('Fetched users:', data.user);
        const currentUserId = parseInt(localStorage.getItem('UserID'), 10);
console.log('Current user ID:', currentUserId);
        
        // Filter out the current user from the user list
        const filteredUsers = data.user.filter(user => {
          console.log('User ID:', user.UserID);
          console.log('Comparison result:', user.UserID !== currentUserId);
          return user.UserID !== currentUserId;
        });
  
        console.log('Filtered users:', filteredUsers);
        
        setUsers(filteredUsers);
            })
      .catch(error => {
        console.error('Error fetching user data:', error);
      });
  
    // Fetch messages between the current user and the selected user
    if (selectedUser) {
      fetchMessages(currentUserId, selectedUser.UserID); // Assuming you have the current user's ID
    }
  }, [selectedUser]);
    
  // Function to handle sending a new message
  // Function to handle sending a new message
const sendMessage = () => {
    if (newMessage.trim() !== '') {
        const formData = new FormData();
        formData.append('senderId',localStorage.getItem('UserID'));
        formData.append('receiverId', selectedUser.UserID);
        formData.append('text', newMessage);
      
      fetch('https://sxg3378.uta.cloud/backend/messages.php', {
        method: 'POST',
        body: formData,
      })
        .then(response => {
          if (response.ok) {
            setMessages([...messages, formData]);
            setNewMessage('');
            fetchMessages(localStorage.getItem('UserID'),selectedUser.UserID);
          } else {
            throw new Error('Failed to send message');
          }
        })
        .catch(error => {
          console.error('Error sending message:', error);
        });
    }
  };
  

  // Function to handle selecting a user
  const handleUserSelect = (user) => {
    setSelectedUser(user);
    // Here you can fetch the messages between the current user and the selected user
  };

  // Function to render the appropriate header based on the current user's role
  const renderHeader = () => {
    switch (currentUserRole) {
      case 'Patient':
        return <PatientHeader />;
      case 'Administrator':
        return <AdministratorHeader />;
      case 'Pharmacist':
        return <PharmacistHeader />;
      case 'Healthcare Provider':
        return <HealthCareProviderHeader />;
      case 'Health Administrator':
        return <HealthAdministratorHeader />;
      default:
        return null;
    }
  };

  return (
    <div>
      {renderHeader()}
      <div className="chat-container">
        <div className="sidebar">
          <h2>Chat With</h2>
          <ul>
            {/* Render user list */}
            {/* {users.map((user, index) => (
              <li key={index} onClick={() => handleUserSelect(user)}>{user.Username}</li>
            ))} */}
            <ul>  
  {/* Render user list */}
  {users.map((user, index) => (
    <li key={index} onClick={() => handleUserSelect(user)}>
      {user.Username}
      {messages1.some(message => message.sender_id == user.UserID) && <span className="blue-icon" style={{color:"blue"}}>●</span>}
    </li>
  ))}
</ul>

          </ul>
        </div>
        <div className="chat-window">
          {/* Render chat box header with selected user's name */}
          {selectedUser && <h2>{selectedUser.Username}</h2>}
          {/* Render messages */}
          {messages !== null && messages.map((message, index) => (
  <div key={index} className="message-container">
    <div className={`message ${message.sender_id === localStorage.getItem('UserID') ? 'user' : 'other'}`}>
      {message.text}
    </div>
    <p className={`sender-name ${message.sender_id === localStorage.getItem('UserID') ? 'user' : 'other'}`}>
      {message.sender_id === localStorage.getItem('UserID') ? 'You: ' : selectedUser.Username+':'} {message.created_at}
    </p>
  </div>
))}
</div>
        <div className="input-container">
          {/* Input field for typing new message */}
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type your message..."
          />
          {/* Button to send message */}
          <button onClick={sendMessage}>Send</button>
        </div>
      </div>
    </div>
  );
};

export default ChatPage;
