import React, { useState, useEffect } from 'react';
import './Patient.css';
import PatientHeader from './PatientHeader';

const PrescriptionManager = () => {
  const [prescriptions, setPrescriptions] = useState([]);
  const [selectedPrescription, setSelectedPrescription] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchPrescriptions();
  }, []);

  const fetchPrescriptions = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('https://sxg3378.uta.cloud/backend/public/api/medicationhistoryuser?id='+localStorage.getItem('UserID'));
      const data = await response.json();
      setPrescriptions(data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching prescriptions:', error);
      setIsLoading(false);
    }
  };

  const handleSelectPrescription = (prescription) => {
    setSelectedPrescription(prescription);
  };

  const handleRefillRequest = (prescriptionId) => {
    alert(`Requested refill for prescription with ID: ${prescriptionId}`);
  };

  const handleAlertSubscription = (prescriptionId) => {
    alert(`Subscribed to alerts for prescription with ID: ${prescriptionId}`);
  };

  return (
    <div>
      <PatientHeader/>
      <div className='container'>
        <h2>Prescription Manager</h2>
        {isLoading && <p>Loading prescriptions...</p>}
        <div className="health-records">
          {prescriptions.map((prescription) => (
            <ul key={prescription.PrescriptionID} className="prescription-item" style={{marginTop:"15px"}}>
              <li>
                {prescription.medications.length > 0 ? (
                  <>
                    <h3>{prescription.medications[0].MedicationName}</h3>
                    <strong>Dosage:</strong> {prescription.medications[0].Dosage} 
                    <strong>Frequency: </strong>{prescription.medications[0].Frequency}
                  </>
                ) : (
                  <h3>No medication information available</h3>
                )}
                <strong style={{marginLeft:"15px"}}>
                  {/* <a
                    href="#"
                    onClick={() => handleSelectPrescription(prescription)}
                    style={{
                      marginRight: '10px',
                      textDecoration: 'none',
                      color: '#007bff',
                      cursor: 'pointer',
                    }}
                  >
                    View Details
                  </a> */}
                  <a
                    href="#"
                    onClick={() => handleRefillRequest(prescription.PrescriptionID)}
                    style={{
                      marginRight: '10px',
                      textDecoration: 'none',
                      color: '#007bff',
                      cursor: 'pointer',
                    }}
                  >
                    Request Refill
                  </a>
                  <a
                    href="#"
                    onClick={() => handleAlertSubscription(prescription.PrescriptionID)}
                    style={{
                      textDecoration: 'none',
                      color: '#007bff',
                      cursor: 'pointer',
                    }}
                  >
                    Subscribe to Alerts
                  </a>
                </strong>
              </li>
            </ul>
          ))}
        </div>
        {selectedPrescription && (
          <div className="prescription-details">
            <h3>Prescription Details</h3>
            {selectedPrescription.medications.length > 0 ? (
              <>
                <p>Medication Name: {selectedPrescription.medications[0].MedicationName}</p>
                <p>Dosage: {selectedPrescription.medications[0].Dosage}</p>
                <p>Frequency: {selectedPrescription.medications[0].Frequency}</p>
                <p>Details: {selectedPrescription.medications[0].Description}</p>
              </>
            ) : (
              <p>No medication information available</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PrescriptionManager;
