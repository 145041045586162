import React, { useRef } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import Header from "./Header";

function Login() {
  const username = useRef();
  const password = useRef();
  const navigate = useNavigate();

  const handleLogin = async () => {
    const userEmail = username.current.value;
    const userPassword = password.current.value;
  
    // Check if email or password fields are empty
    if (!userEmail || !userPassword) {
      alert('Please enter both email and password');
      return;
    }
  
    // Check if email is in a valid format
    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!emailRegex.test(userEmail)) {
      alert('Please enter a valid email address');
      return;
    }
  
    // Check if password meets minimum length criteria
    if (userPassword.length < 6) {
      alert('Password must be at least 6 characters long');
      return;
    }
  
    const data = {
      email: username.current.value,
      password: password.current.value
    };

    try {
      const response = await fetch('https://sxg3378.uta.cloud/backend/public/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Login failed');
      }

      const responseData = await response.json();
      if(responseData.status=='success')
      {
        localStorage.setItem('email',responseData.user['Email']);
        localStorage.setItem('role',responseData.user['Role']);
        localStorage.setItem('UserID',responseData.user['UserID']);
        localStorage.setItem('username',responseData.user['Username']);

      const currentUser = responseData.user;
      if (currentUser.Role === 'Patient') {
        window.location.href = "/patients";
      } else if (currentUser.Role === 'Administrator') {
        window.location.href = "/administrator";
      } else if (currentUser.Role === 'Pharmacist') {
        window.location.href = "/medicationhistory";
      } else if (currentUser.Role === 'Healthcare Provider') {
        window.location.href = "/healthcareproviders";
      } else if (currentUser.Role === 'Health Administrator') {
        window.location.href = "/healthadministrator";
      }
      
    }
    else
    {
      alert('Invalid Credentials');
    }
    } catch (error) {
      alert('Invalid Credentials');
      console.error('Login error:', error.message);
      // Handle login error here, such as displaying an error message
    }
  }

  const handleBackClick = () => {
    navigate("/");
  };
  
  return (
    <>
      <Header />
      <div className="container" style={{maxWidth: "500px", marginLeft:"auto", marginRight:"auto"}}>
        <div className="card">
          <div>
            <img src="36.png" style={{ width:"100%", height:"auto" }}/>
            <div className="login" style={{ textAlign: "left", marginTop: "25px" }}>
              <h2 style={{ textAlign: "center" }}>Login </h2>              
              <label>UserName:</label>
              <input ref={username} type="text" id="username" name="username" required/>

              <label>Password:</label>
              <input ref={password} type="password" id="password" name="password" required/>
              <button onClick={handleLogin} type="submit" style={{ width: "100%", marginTop:"25px", borderRadius:"0px" }}>
                Login
              </button>            
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
