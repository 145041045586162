import React, { useState, useEffect } from 'react';
import HealthCareProviderHeader from './HealthCareProviderHeader';
// import './ProfessionalCollaboration.css';

function ProfessionalCollaboration() {
  const [posts, setPosts] = useState([]);
  const [newPostContent, setNewPostContent] = useState('');

  // Fetch posts from the server
  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    try {
      const response = await fetch('https://sxg3378.uta.cloud/backend/public/api/getAllPosts');
      const data = await response.json();
      setPosts(data.posts);
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };

  const createPost = async () => {
    try {
      await fetch('https://sxg3378.uta.cloud/backend/public/api/addPost', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ content: newPostContent,userId:localStorage.getItem('UserID') })
      });
      setNewPostContent('');
      // After creating the post, fetch updated posts
      fetchPosts();
    } catch (error) {
      console.error('Error creating post:', error);
    }
  };

  return (
    <>
      <HealthCareProviderHeader />
      <div className='container health-forum-container'>
        <h1 className='health-forum-header'>Health Forum</h1>
        {/* New post form */}
        <div className='new-post-form'>
          <textarea
            value={newPostContent}
            onChange={(e) => setNewPostContent(e.target.value)}
            placeholder="Write your post..."
          ></textarea>
          <button onClick={createPost}>Post</button>
        </div>
        {/* Display existing posts */}
        <div className='posts-list'>
          {posts!==null && posts.map((post, index) => (
            <div key={index} className='post-item'>
              <p>{post.Topic}</p>
              <p>by:{post.user['Username']}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default ProfessionalCollaboration;
