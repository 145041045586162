import React from 'react'
import {BrowserRouter as Router,Routes,Route, useNavigate}  from 'react-router-dom';
import Header from './Header';


function AboutUs() {
    const navigate=useNavigate();

    const handleBackClick=()=>{
        navigate('/')
    }
  return (
    <><Header/>
   {/* <div className='about-us'>
    <div className='container'>
      <div className='row'>
        <div className='flex'>
          <h2>About-Us</h2> 
          <h1>Welcome to VolunteerConnect</h1>
          <h3>Best Care for your Good Health</h3>
          <p>
          Esteem spirit temper too say adieus who direct esteem. It esteems luckily or picture placing drawing. Apartments frequently or motionless on reasonable projecting expression.
          </p>
          <button className='btn' >Learn More</button>
          </div>
          <div>
            <img src="https://images.hindustantimes.com/rf/image_size_960x540/HT/p2/2017/07/20/Pictures/_ba66f8c8-6d3a-11e7-9994-94edcc701b36.jpg"></img>
          </div>
         
      </div>
    </div>
   </div>
    */}
   <section className="about-us">
      <div className="container">
        <h2>About Us</h2>
        <div className="about-content">
          <div className="about-text">
            <p>SmartHealth Hub is a cutting-edge website aiming to revolutionize healthcare accessibility and management. This project combines healthcare, technology, and user experience, addressing the critical need for an integrated healthcare ecosystem. The focus is on data security, interoperability, and providing a user-friendly platform for both patients and healthcare professionals.</p>
            <p>We are committed to making healthcare more accessible and efficient for everyone.</p>
          </div>
          <div className="about-image">
          <img src="https://images.hindustantimes.com/rf/image_size_960x540/HT/p2/2017/07/20/Pictures/_ba66f8c8-6d3a-11e7-9994-94edcc701b36.jpg"></img>          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default AboutUs