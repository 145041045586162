import React, { useState, useEffect } from 'react';
import HealthCareProviderHeader from './HealthCareProviderHeader';
import { useNavigate } from 'react-router-dom';

function HealthAppointmentManagement() {
  const [appointments, setAppointments] = useState([]);
  const userId = localStorage.getItem('UserID'); // Set the UserID here
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch appointments data from the API
    fetchAppointments(userId);
  }, [userId]); // Call useEffect whenever userId changes

  // Function to fetch appointments data
  const fetchAppointments = (userId) => {
    fetch(`https://sxg3378.uta.cloud/backend/public/api/healthcareappointments?UserID=${userId}`)
      .then(response => response.json())
      .then(data => setAppointments(data))
      .catch(error => console.error('Error fetching appointments:', error));
  };

  // Function to update appointment status
  const updateAppointmentStatus = (id, status) => {
    // Make PUT request to update appointment status
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ AppointmentID: id, Status: status })
    };

    fetch("https://sxg3378.uta.cloud/backend/public/api/updateappointments", requestOptions)
      .then(response => response.json())
      .then(data => {
        // Update appointments state with the updated appointment
        const updatedAppointments = appointments.map(appointment => {
          if (appointment.AppointmentID === id) {
            return { ...appointment, Status: status };
          }
          return appointment;
        });
        setAppointments(updatedAppointments);
      })
      .catch(error => console.error('Error updating appointment status:', error));
  };
  const handlePrescription = (appointmentID, patientID,patientName) => {
    // Update appointment status
    updateAppointmentStatus(appointmentID, 'Completed');

    // Store patientID and appointmentID in localStorage
    localStorage.setItem('patientID', patientID);
    localStorage.setItem('appointmentID', appointmentID);
    localStorage.setItem('patientName', patientName);

    // Navigate to /eprescriptions
    navigate('/eprescriptions');
  };
  return (
    <>
      <HealthCareProviderHeader />
      <div style={{ maxWidth: "1200px", margin: "10px 15px" }}>
        <h1 style={{ textAlign: "center" }}>Health Appointment Management</h1>
        <h2>Appointments</h2>
        <div className="section health-record">
          <ul>
            {appointments.map(appointment => (
              <li key={appointment.AppointmentID}>
                {appointment.Username} - {appointment.AppointmentDate} - {appointment.AppointmentTime} - {appointment.Specialization} - {appointment.Status}
               {appointment.Status=='Scheduled' && (<> 
                <button onClick={() => updateAppointmentStatus(appointment.AppointmentID, 'Completed')} className='button' style={{ background: "", borderRadius: "0px" }}>Complete</button>
                <button onClick={() => updateAppointmentStatus(appointment.AppointmentID, 'Cancelled')} className='button' style={{ background: "#f17171", borderRadius: "0px" ,border:"1px #f17171",marginLeft:"5px"}}>Cancel</button>
                <button onClick={() => handlePrescription(appointment.AppointmentID, appointment.patientID,appointment.Username)} className='button' style={{ background: "", borderRadius: "0px" ,border:"",marginLeft:"5px"}}>EPrescription</button>

                
                </>)}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}

export default HealthAppointmentManagement;
