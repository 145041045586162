import React from 'react'
import {BrowserRouter as Router,Routes,Route, useNavigate}  from 'react-router-dom';
import Header from './Header';

function Contact() {
    const navigate=useNavigate();

    const handleBackClick=()=>{
        navigate('/')
    }
  return (
    <><Header/>
     <div className="contact-us-page">
      <div className="contact-header">
        <h1>Contact Us</h1>
      </div>
      <div className="contact-details">
        <div className="contact-item">
          <h3>Location</h3>
          <p>123 Street Name, City, Country</p>
        </div>
        <div className="contact-item">
          <h3>Email</h3>
          <p>contact@example.com</p>
        </div>
        <div className="contact-item">
          <h3>Mobile Number</h3>
          <p>+1234567890</p>
        </div>
      </div>
      <div className="contact-form-container">
        <div className="contact-form">
          <h2>Send us a message</h2>
          <form>
            <div className="form-row">
              <input type="text" name="fname" placeholder="First Name" />
              <input type="text" name="lname" placeholder="Last Name" />
            </div>
            <div className="form-row">
              <input type="email" name="email" placeholder="Email" />
              <input type="Mobile Number" name="email" placeholder="Mobile Number" />

            </div>
            <textarea placeholder="Your Message"></textarea>

            <div className="contactbutton">
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
      </div>
      <footer className="footer">
        <p>&copy; 2022 Health Management System. All rights reserved.</p>
      </footer>
     
    </>
  )
}

export default Contact