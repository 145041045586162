import React, { useState, useEffect } from 'react';
import PatientHeader from './PatientHeader';
import './Patient.css';

const AppointmentManagement = () => {
  const [patientName, setPatientName] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [specialization, setSpecialization] = useState('');
  const [doctor, setDoctor] = useState('');
  const [appointments, setAppointments] = useState([]);
  const [specialties, setSpecialties] = useState([]);

  // Fetch specialties on component mount
  useEffect(() => {
    fetchSpecialties();
    fetchUserAppointments(); // Fetch user appointments when component mounts

  }, []);

  const fetchUserAppointments = () => {
    const userId = localStorage.getItem('UserID'); // Get UserID from localStorage or wherever it's stored
    fetch(`https://sxg3378.uta.cloud/backend/public/api/userappointments?UserID=${userId}`)
      .then(response => response.json())
      .then(data => {
        setAppointments(data);
      })
      .catch(error => console.error('Error fetching user appointments:', error));
  };
  // Function to fetch specialties
  const fetchSpecialties = () => {
    fetch('https://sxg3378.uta.cloud/backend/public/api/specialties')
      .then(response => response.json())
      .then(data => {
        setSpecialties(data);
      })
      .catch(error => console.error('Error fetching specialties:', error));
  };

  // Function to fetch doctors based on selected specialty
  const fetchDoctors = (specialtyName) => {
    const specialty = specialties.find(spec => spec.name === specialtyName);
    if (specialty) {
      setDoctor('');
      setSpecialization(specialtyName);
    }
  };

  // Function to handle adding an appointment
  const addAppointment = () => {
    if (patientName.trim() === '' || date === '' || time === '' || specialization === '' || doctor === '') {
      alert('Please fill in all fields.');
      return;
    }

    // Create a new appointment object
    const newAppointment = {
      UserID: localStorage.getItem('UserID'), 
      ProviderID: doctor, // Use the selected doctor's ProviderID
      AppointmentDate: `${date} ${time}`,
      AppointmentTime: `${time}`,
      Status: 'Scheduled'
    };

    // Make a POST request to create a new appointment
    fetch('https://sxg3378.uta.cloud/backend/public/api/appointments', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(newAppointment)
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        // Update the local state with the newly created appointment
        fetchUserAppointments();
        // setAppointments([...appointments, data]);
        // Reset form fields
        setPatientName('');
        setDate('');
        setTime('');
        setSpecialization('');
        setDoctor('');
      })
      .catch(error => {
        console.error('Error adding appointment:', error);
        alert('An error occurred while adding the appointment. Please try again.');
      });
  };

  // Function to remove an appointment
  const removeAppointment = (appointmentId) => {
    // Make a DELETE request to remove the appointment
    fetch(`https://sxg3378.uta.cloud/backend/public/api/appointments/${appointmentId}`, {
      method: 'DELETE'
    })
      .then(response => {
        console.log(response);
        // Filter out the removed appointment from the local state
        const updatedAppointments = appointments.filter(appointment => appointment.AppointmentID !== appointmentId);
        setAppointments(updatedAppointments);
      })
      .catch(error => {
        console.error('Error removing appointment:', error);
        alert('An error occurred while removing the appointment. Please try again.');
      });
  };

  return (
    <>
      <PatientHeader />
      <div className='appointment-management card' style={{ textAlign: 'left' }}>
        <h2 style={{ textAlign: "center" }}>Appointment Management</h2>
        <div className='add-appointment'>
          <h3>Add Appointment</h3>
          <input
            type='text'
            placeholder='Patient Name'
            value={patientName}
            onChange={(e) => setPatientName(e.target.value)}
          />
          <input
            type='date'
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
          <input
            type='time'
            value={time}
            onChange={(e) => setTime(e.target.value)}
          />
          <br/>
          <select
            value={specialization}
            onChange={(e) => fetchDoctors(e.target.value)}
            style={{ marginRight: '10px' }}
          >
            <option value="">Select Specialization</option>
            {specialties.map(spec => (
              <option key={spec.id} value={spec.name}>{spec.name}</option>
            ))}
          </select>
          <br></br>
          <select
            value={doctor}
            onChange={(e) => setDoctor(JSON.parse(e.target.value))}
            style={{ marginRight: '10px' }}
          >
            <option value="">Select Doctor</option>
            {specialization && specialties.find(spec => spec.name === specialization)?.healthcare_providers.map(provider => (
              <option key={provider.ProviderID} value={JSON.stringify(provider.UserID)}>
                {provider.user.Username}
              </option>
            ))}
          </select>
          <button onClick={addAppointment}>Add Appointment</button>
        </div>
        <br/>
        <div className='appointments'>
          <h3>Upcoming Appointments</h3>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Time</th>
                <th>Specialization</th>
                <th>Doctor</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {appointments.map((appointment) => (
                <tr key={appointment.AppointmentID}>
                  {/* <td>{appointment.Username}</td> */}
                  <td>{appointment.AppointmentDate}</td>
                  <td>{appointment.AppointmentTime}</td>
                  <td>{appointment.Specialization}</td>
                  <td>{appointment.Username}</td> {/* Display doctor's username */}
                  <td>
                    <button onClick={() => removeAppointment(appointment.AppointmentID)}>Remove</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default AppointmentManagement;
