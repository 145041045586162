import React, { useState } from 'react';
import PatientHeader from './PatientHeader';

const CommunityInteraction = () => {
  const [posts, setPosts] = useState([]);
  const [newPostText, setNewPostText] = useState('');

  // Function to add a new post
  const addPost = () => {
    if (newPostText.trim() !== '') {
      const newPost = {
        id: posts.length + 1,
        text: newPostText,
      };
      setPosts([...posts, newPost]);
      setNewPostText('');
    }
  };

  // Function to remove a post
  const removePost = (postId) => {
    const updatedPosts = posts.filter((post) => post.id !== postId);
    setPosts(updatedPosts);
  };

  return (
    <>
    <PatientHeader/>
    <div>
      <h2>Community Interaction</h2>
      <div>
        <h3>Create New Post</h3>
        <textarea
          value={newPostText}
          onChange={(e) => setNewPostText(e.target.value)}
          placeholder="Write your post here..."
          rows="4"
        ></textarea>
        <button onClick={addPost}>Post</button>
      </div>

      <div>
        <h3>Recent Posts</h3>
        <ul>
          {posts.map((post) => (
            <li key={post.id}>
              <p>{post.text}</p>
              <button onClick={() => removePost(post.id)}>Delete</button>
            </li>
          ))}
        </ul>
      </div>
    </div>
    </>
  );
};

export default CommunityInteraction;