import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Header from './Header';

function VerifyEmailPage() {
  // State to store the email address
  const [email, setEmail] = useState('');

  // Get the current URL location
  const location = useLocation();

  useEffect(() => {
    // Extract email parameter from the URL
    const searchParams = new URLSearchParams(location.search);
    const userEmail = searchParams.get('email');
    
    // Set the email address
    if (userEmail) {
      setEmail(decodeURIComponent(userEmail));
      // Optionally, you can make an API call here to verify the email address
    }
  }, [location.search]);

  return (
    <>
    <Header/>
    <div className="container" style={{maxWidth: "500px", marginLeft:"auto", marginRight:"auto"}}>
      <div className="card">
    <div>
      <h2>Email Verification</h2>
      {email && (
        <div>
          <p>Thank you for verifying your email address:</p>
          <p>{email}</p>
          {/* Optionally, you can add a success message */}
          {/* <p>Your email address has been successfully verified.</p> */}
        </div>
      )}
    </div>
    </div></div></>
  );
}

export default VerifyEmailPage;
