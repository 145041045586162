import React, { useRef, useEffect,useState } from 'react';
import PatientHeader from './PatientHeader';
import Chart from 'chart.js/auto'; // Import Chart from chart.js/auto
import '../PatientsComponent/Patient.css';

function Patients() {
    const [appointments, setAppointments] = useState([]);

  const patientInfo = {
    name: 'John Doe',
    age: 35,
    gender: 'Male',
    healthRecords: [
      { date: '2022-01-15', hbA1c: 6.5 },
      { date: '2022-02-20', hbA1c: 6.3 },
      { date: '2022-03-10', hbA1c: 6.8 },
      
    ]
  };

  const fetchUserAppointments = () => {
    const userId = localStorage.getItem('UserID'); 
    fetch(`https://sxg3378.uta.cloud/backend/public/api/userappointments?UserID=${userId}`)
      .then(response => response.json())
      .then(data => {
        setAppointments(data);
      })
      .catch(error => console.error('Error fetching user appointments:', error));
  };

  const chartRef = useRef(null);
  const chartInstance = useRef(null);
useEffect(() => {
  fetchUserAppointments();
},10000);
  useEffect(() => {
    
    if (chartInstance.current !== null) {
      chartInstance.current.destroy(); // Destroy existing chart instance
    }

    const ctx = chartRef.current.getContext('2d');
    chartInstance.current = new Chart(ctx, {
      type: 'line',
      data: {
        labels: patientInfo.healthRecords.map(record => record.date),
        datasets: [{
          label: 'HbA1c',
          data: patientInfo.healthRecords.map(record => record.hbA1c),
          fill: true,
          borderColor: 'rgba(75, 192, 192, 1)',
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderWidth: 1,
        }]
      },
      options: {
        scales: {
          x: {
            title: {
              display: true,
              text: 'Date',
            },
          },
          y: {
            title: {
              display: true,
              text: 'HbA1c Value',
            },
          },
        },
      }
    });

    return () => {
      if (chartInstance.current !== null) {
        chartInstance.current.destroy(); // Cleanup on unmount
      }
    };
  }, [patientInfo.healthRecords]);
  const user_id = localStorage.getItem('UserID');
  const [userPHR, setUserPHR] = useState(null);

  useEffect(() => {
    // Fetch user's PHR data
    const fetchPHR = async () => {
      try {
        
        const response = await fetch('https://sxg3378.uta.cloud/backend/public/api/phr?id='+user_id); // Replace '24' with the actual user id
        if (response.ok) {
          const data = await response.json();
          setUserPHR(data.user);
        } else {
          console.error('Failed to fetch user PHR data');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchPHR();
  }, []); // Empty dependency array ensures this effect runs only once, equivalent to componentDidMount

  return (
    <>
      <PatientHeader />
      <div className="patient-dashboard">
        <h2>Patient Dashboard</h2>
        <div className="patient-info">
          <button type='submit' style={{marginRight:"10px"}}><a href="/symptomchecker" style={{textDecoration:"none",color:"white"}}>Symptom Checker</a></button>
          <button type='submit' style={{marginRight:"10px"}}><a href="/prescriptionmanagement" style={{textDecoration:"none",color:"white"}}>Prescription Management</a></button>
          
        </div>
        <div className="patient-info">
          <h3>Patient Information</h3>
          <p><strong>Name:</strong> {localStorage.getItem('username')}</p>
          <p><strong>Age:</strong> {patientInfo.age}</p>
          <p><strong>Gender:</strong> {patientInfo.gender}</p>
        </div>
        <div className="health-records">
          <h3>Health Records</h3>
          <ul>
            {userPHR!=null && userPHR.map((record, index) => (
              <li key={index}>
                <strong>Medication:</strong> {record.medicalHistory}, <strong>Prescription:</strong> {record.prescriptions}, <strong>vitalSigns:</strong>{record.vitalSigns},<strong>Exercise:</strong>{record.exerciseData}
              </li>
            ))}
          </ul>
        </div>
        <div className="appointments">
          <h3>Upcoming Appointments</h3>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Time</th>
                <th>Doctor</th>
                <th>Specialization</th>
              </tr>
            </thead>
            <tbody>
              {appointments.map((appointment, index) => (
                <tr key={index}>
                  <td>{appointment.AppointmentDate}</td>
                  <td>{appointment.AppointmentTime}</td>
                  <td>{appointment.Username}</td>
                  <td>{appointment.Specialization}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="health-records-chart">
          <h3>HbA1c Trend</h3>
          <canvas ref={chartRef}></canvas>
        </div>
      </div>
    </>
  );
}

export default Patients;
