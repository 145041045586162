import React from 'react'
import HealthAdministratorHeader from './HealthAdministratorHeader'
import {BrowserRouter as Router,Routes,Route,Link, useNavigate}  from 'react-router-dom';

function HealthAdministrator() {
  return (
    <><HealthAdministratorHeader/>
    <div className='card'>
    <Link to='/staff'>StaffCoordination</Link>
    </div>
    <div className='card'>
    <Link to='/compliance'>ComplianceOveright</Link>
    </div>
    <div className='card'>
    <Link to='/incidentresponse'>IncidentResponse</Link>
    </div>
    <div className='card'>
    <Link to='/facility'>FacilityMangement</Link>
    </div>
   
    
    </>
  )
}

export default HealthAdministrator