import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const hamburger = document.getElementById('hamburger');
    const close = document.getElementById('close');
    const nav = document.getElementById('nav');

    const handleClick = () => {
      nav.classList.toggle('active');
      hamburger.classList.toggle('hide');
      close.classList.toggle('show');
    };

    hamburger.addEventListener('click', handleClick);

    close.addEventListener('click', () => {
      nav.classList.remove('active');
      hamburger.classList.remove('hide');
      close.classList.remove('show');
    });

    return () => {
      hamburger.removeEventListener('click', handleClick);
      close.removeEventListener('click', handleClick);
    };
  }, []);

  // Retrieve user information from localStorage
  const userEmail = localStorage.getItem('email');
  const userRole = localStorage.getItem('role');
  const handleLogout = () => {
    localStorage.removeItem('email');
    localStorage.removeItem('role');
    navigate('/');
  };

  return (
    <header>
      <div className="" style={{display:"flex",margin: "0 auto",padding: "0 20px"}}>
        <div className="logo"><a href="/"><img src='logo.jpg' style={{width:"25%"}} alt="Logo"></img></a></div>
        <nav id="nav">
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/contact">Contact</Link></li>
            <li><Link to="/service">Service</Link></li>
            {!userEmail && (
              <>
                <li><Link to="/login">Login</Link></li>
                <li><Link to="/register">Sign Up</Link></li>
              </>
            )}
            {userEmail && (
              <>
                {userRole === 'Patient' && (
                  <li><Link to="/patients">Dashboard</Link></li>
                )}
                {userRole === 'Administrator' && (
                  <li><Link to="/administrator">Dashboard</Link></li>
                )}
                {userRole === 'Pharmacist' && (
                  <li><Link to="/pharmacist">Dashboard</Link></li>
                )}
                {userRole === 'Healthcare Provider' && (
                  <li><Link to="/healthcareproviders">Dashboard</Link></li>
                )}
                {userRole === 'healthcareadmin' && (
                  <li><Link to="/healthadministrator">Dashboard</Link></li>
                )}
                                <li><a onClick={handleLogout} href="#">Logout</a></li>

              </>
            )}
            <li><a href="https://sxg3378.uta.cloud/blog" target='_blank' rel="noopener noreferrer">Blog</a></li>
          </ul>
        </nav>
        <div className="hamburger" id="hamburger">
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <div className="close" id="close">
          &times;
        </div>
      </div>
    </header>
  );
}

export default Header;
