import React, { useState, useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import { Link } from 'react-router-dom';
import HealthCareProviderHeader from './HealthCareProviderHeader';
import './style.css';
import { Line } from 'react-chartjs-2';

function HealthCareProviders() {

  
  const sampleHealthRecords = [
    { id: 1, patientName: 'John Doe', diagnosis: 'Fever', prescription: 'Paracetamol' },
    { id: 2, patientName: 'Jane Smith', diagnosis: 'Headache', prescription: 'Ibuprofen' },
    // More sample health records...
  ];

  const sampleMessages = [
    { id: 1, sender: 'Patient A', message: 'I am experiencing chest pain. What should I do?', date: '2024-03-06' },
    { id: 2, sender: 'Patient B', message: 'I need a refill on my prescription.', date: '2024-03-05' },
    // More sample messages...
  ];
  const [patientData, setPatientData] = useState([]);
  const userId=localStorage.getItem('UserID');
  useEffect(() => {
    fetchPatientData();
    fetchAppointments(userId);
  }, []);

  const fetchPatientData = async () => {
    try {
      // Dummy patient data (replace with actual API call)
      const data = [
        { date: '2024-01-01', weight: 70, bloodPressure: 120 },
        { date: '2024-01-02', weight: 69, bloodPressure: 118 },
        { date: '2024-01-03', weight: 68, bloodPressure: 115 },
        // Add more dummy data as needed
      ];
      setPatientData(data);
    } catch (error) {
      console.error('Error fetching patient data:', error);
    }
  };

  const [appointments, setAppointments] = useState([]);
  const [healthRecords, setHealthRecords] = useState([]);
  const [messages, setMessages] = useState([]);
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const fetchAppointments = (userId) => {
    fetch(`https://sxg3378.uta.cloud/backend/public/api/healthcareappointments?UserID=${userId}`)
      .then(response => response.json())
      .then(data => setAppointments(data))
      .catch(error => console.error('Error fetching appointments:', error));
  };


  useEffect(() => {
    // Simulating fetching data from APIs
    // setAppointments(sampleAppointments);
    setHealthRecords(sampleHealthRecords);
    setMessages(sampleMessages);
  }, []);

  useEffect(() => {
    // Draw chart when data updates
    if (appointments.length > 0 || healthRecords.length > 0 || messages.length > 0) {
      drawChart();
    }
  }, [appointments, healthRecords, messages]);

  const drawChart = () => {
    const ctx = chartRef.current.getContext('2d');
    if (chartInstance.current !== null) {
      chartInstance.current.destroy();
    }
    chartInstance.current = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['Appointments', 'Health Records', 'Messages'],
        datasets: [{
          label: 'Number of Entries',
          data: [appointments.length, healthRecords.length, messages.length],
          backgroundColor: [
            'rgba(54, 162, 235, 0.5)',
            'rgba(255, 99, 132, 0.5)',
            'rgba(255, 206, 86, 0.5)'
          ],
          borderColor: [
            'rgba(54, 162, 235, 1)',
            'rgba(255, 99, 132, 1)',
            'rgba(255, 206, 86, 1)'
          ],
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });
  };
  const chartData = {
    labels: patientData.map((dataPoint) => dataPoint.date),
    datasets: [
      {
        label: 'Weight (kg)',
        data: patientData.map((dataPoint) => dataPoint.weight),
        borderColor: 'rgba(75, 192, 192, 1)',
        fill: false,
      },
      {
        label: 'Blood Pressure',
        data: patientData.map((dataPoint) => dataPoint.bloodPressure),
        borderColor: 'rgba(255, 99, 132, 1)',
        fill: false,
      },
    ],
  };

 
  return (
    <>
      <HealthCareProviderHeader />
      
      
      <div className='container'>
        <h1>Healthcare Provider Dashboard</h1>
        <div className='row-flex' style={{color:"white",textDecoration:"none"}}>
      <div className='col-4 button' style={{color:"white",textDecoration:"none"}}>
        <Link to='/eprescription' style={{color:"white",textDecoration:"none"}}>Eprescriptions</Link>
      </div>
      {/* <div className='col-4 button' style={{fontColor:"white",textDecoration:"none"}}>
        <Link to='/securemessaging' style={{color:"white",textDecoration:"none"}}>SecureMessaging</Link>
      </div> */}
      <div className='col-4 button' style={{fontColor:"white",textDecoration:"none"}}>
        <Link to='/professionalcollab' style={{color:"white",textDecoration:"none"}}>Professional Collaboration</Link>
      </div>
      </div>
    
        <div className='section appointment'>
          <h2>Appointments</h2>
          <ul>
            {appointments.map(appointment => (
              <li key={appointment.AppointmentID}>{appointment.Username} - {appointment.AppointmentDate} </li>
            ))}
          </ul>
        </div>

        <div className='section health-record'>
          <h2>Health Records</h2>
          <ul>
            {healthRecords.map(record => (
              <li key={record.id}>{record.patientName} - {record.diagnosis} - Prescription: {record.prescription}</li>
            ))}
          </ul>
        </div>

        <div className='section message'>
          <h2>Messages</h2>
          <ul>
            {messages.map(message => (
              <li key={message.id}><strong>{message.sender}</strong> - {message.message} - Date: {message.date}</li>
            ))}
          </ul>
        </div>
        <div className="section chart">
          <h2>Data Overview</h2>
          <canvas ref={chartRef}></canvas>
        </div>
        <div className="analytics-card">
        <h2>Patient Health Trends</h2>
        <Line data={chartData} />
      </div>
      
      </div>
    </>
  )
}

export default HealthCareProviders;
