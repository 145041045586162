import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PharmacistHeader from './PharmacistHeader';

function MedicationHistory() {
  const [medicationHistory, setMedicationHistory] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    if (selectedDate !== null) {
      const formattedDate = selectedDate.toISOString().slice(0, 10);
      fetchMedicationHistory(formattedDate);
    }
    fetchMedicationHistory('2024-04-22');
  }, [selectedDate]);

  const fetchMedicationHistory = async (date) => {
    try {
      const response = await fetch(`https://sxg3378.uta.cloud/backend/public/api/medication-history?date=${date}`);
      if (!response.ok) {
        throw new Error('Failed to fetch medication history');
      }
      const data = await response.json();
      setMedicationHistory(data);
    } catch (error) {
      console.error('Error fetching medication history:', error);
    }
  };

  return (
    <>
      <PharmacistHeader />
      <div className='container'>
        <h1>Medication History</h1>
        <div>
          <label>Select Date:</label>
          <DatePicker
            selected={selectedDate}
            onChange={date => setSelectedDate(date)}
            placeholderText="Select Date"
            dateFormat="yyyy-MM-dd"
          />
        </div>
        {medicationHistory.length > 0 ? (
          <div>
            <h2>Medication History for {selectedDate && selectedDate.toISOString().slice(0, 10)}</h2>
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Medication Name</th>
                  <th>Dosage</th>
                  <th>Prescribed By</th>
                </tr>
              </thead>
              <tbody>
                {medicationHistory.map(history => (
                  <tr key={history.id}>
<td>{new Date(history.created_at).toISOString().slice(0, 10)}</td>
                    <td>
                      <ul>
                        {history.medications.map(medication => (
                          <li key={medication.MedicationID}>
                            {medication.MedicationName}
                          </li>
                        ))}
                      </ul>
                    </td>
                    <td>
                      <ul>
                        {history.medications.map(medication => (
                          <li key={medication.MedicationID}>
                            {medication.Dosage}
                          </li>
                        ))}
                      </ul>
                    </td>
                    <td>{history.user.Username}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p>No medication history found for selected date.</p>
        )}
      </div>
    </>
  );
}

export default MedicationHistory;
