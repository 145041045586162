import React from 'react';
// import 'Features.css'; // Import CSS for styling

const Features = () => {
  return (
    <section className="features">
      <div className="container">
        <h2>Key Features</h2>
        <div className="feature">
          <div className="card">
            <img src="https://img.freepik.com/free-photo/happy-mature-businessman-using-digital-tablet-while-talking-healthcare-workers-hallway-clinic_637285-513.jpg?t=st=1709657137~exp=1709660737~hmac=d70a75ee26780422311319974abd0935847116e5718c8485a881b19ef930071d&w=996" alt="Healthcare Professionals" />
            <h3>Healthcare Professionals</h3>
            <p>Access patient records securely and efficiently</p>
          </div>
        </div>
        <div className="feature">
          <div className="card">
            <img src="https://img.freepik.com/free-vector/flat-style-illustration-various-medical-elements-health-medical-concept_1302-5669.jpg?t=st=1709657200~exp=1709660800~hmac=9c920063721cbbf7dad9990c7ec3dba4bc97427ee313e80af2a2e2f36e13803f&w=740" alt="Patients" />
            <h3>Patients</h3>
            <p>Manage your health information and appointments with ease</p>
          </div>
        </div>
        <div className="feature">
          <div className="card">
            <img src="https://img.freepik.com/free-vector/health-technology-service_24877-52075.jpg?t=st=1709657098~exp=1709660698~hmac=8a6a7056eb4e12821ad2be056fc482e2728553f5d863dde3acace579b6f4921b&w=740" alt="Data Security" />
            <h3>Data Security</h3>
            <p>Our platform ensures the highest standards of data security</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;
