import React, { useState, useEffect } from 'react';
import AdministratorHeader from './AdministratorHeader';

const DataOversight = () => {
  const [dataIntegrityIssues, setDataIntegrityIssues] = useState([]);
  const [dataBreaches, setDataBreaches] = useState([]);
  const [privacyConcerns, setPrivacyConcerns] = useState([]);
  const [systemMalfunctions, setSystemMalfunctions] = useState([]);

  useEffect(() => {
    const dataIntegrityIssuesData = [
      "Inconsistent data in user profiles",
      "Duplicate entries in the database",
      "Missing data in transaction records"
    ];

    const dataBreachesData = [
      "Unauthorized access to sensitive customer information",
      "Leaked login credentials",
      "Security vulnerability exploited by hackers"
    ];
  const privacyConcernsData = [
      "User data shared with third-party advertisers without consent",
      "Insufficient encryption of sensitive data",
      "Failure to comply with data protection regulations"
    ];

    const systemMalfunctionsData = [
      "Database server outage",
      "Slow response times in API endpoints",
      "Application crashes due to memory leaks"
    ];

    setDataIntegrityIssues(dataIntegrityIssuesData);
    setDataBreaches(dataBreachesData);
    setPrivacyConcerns(privacyConcernsData);
    setSystemMalfunctions(systemMalfunctionsData);
  }, []);

  return (
    <div>
      <AdministratorHeader/>
      <h1>Admin Dashboard</h1>
      <div className='container'>
      <div className='section health-record'>
        <h2>Data Integrity Issues</h2>
        {dataIntegrityIssues.length > 0 ? (
          <ul>
            {dataIntegrityIssues.map((issue, index) => (
              <li key={index}>{issue}</li>
            ))}
          </ul>
        ) : (
          <p>No data integrity issues found.</p>
        )}
      </div>
      <div className='section health-record'>
        <h2>Data Breaches</h2>
        {dataBreaches.length > 0 ? (
          <ul>
            {dataBreaches.map((breach, index) => (
              <li key={index}>{breach}</li>
            ))}
          </ul>
        ) : (
          <p>No data breaches found.</p>
        )}
      </div>
      <div className='section health-record'>
        <h2>Privacy Concerns</h2>
        {privacyConcerns.length > 0 ? (
          <ul>
            {privacyConcerns.map((concern, index) => (
              <li key={index}>{concern}</li>
            ))}
          </ul>
        ) : (
          <p>No privacy concerns found.</p>
        )}
      </div>
      <div className='section health-record'>
        <h2>System Malfunctions</h2>
        {systemMalfunctions.length > 0 ? (
          <ul>
            {systemMalfunctions.map((malfunction, index) => (
              <li key={index}>{malfunction}</li>
            ))}
          </ul>
        ) : (
          <p>No system malfunctions found.</p>
        )}
      </div>
      </div>
    </div>
  );
};

export default DataOversight;
