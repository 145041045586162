import React, { useState, useEffect } from 'react';
import AdministratorHeader from './AdministratorHeader';

function HealthcareProviderManagement() {
  const [providers, setProviders] = useState([]);
  const [newProvider, setNewProvider] = useState({ UserID: null, Username: '', Password: '', Email: '', Address: '', Phone: '', Specialization: '', LicenceNumber: '', Education: '', ExperienceYears: '', Status:'Active', DateOfBirth:'1999-01-01', Role:'Healthcare Provider' });

  useEffect(() => {
    fetchProviders();
  }, []);

  const fetchProviders = async () => {
    try {
      const response = await fetch('https://sxg3378.uta.cloud/backend/public/api/healthcareproviders');
      const data = await response.json();
      setProviders(data.users);
    } catch (error) {
      console.error('Error fetching providers:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProvider({ ...newProvider, [name]: value });
  };

  const createOrUpdateProvider = async () => {
    try {
      const requiredFields = ['Username', 'Password', 'Email', 'Address', 'Phone', 'Specialization', 'LicenceNumber', 'Education', 'ExperienceYears'];
      for (const field of requiredFields) {
        if (!newProvider[field]) {
          alert(`Please fill in ${field}`);
          return;
        }
      }
      if (newProvider.UserID) {
        // Update existing provider
        await fetch(`https://sxg3378.uta.cloud/backend/public/api/healthcareproviders/${newProvider.UserID}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newProvider),
        });
      } else {
        // Create new provider
        await fetch('https://sxg3378.uta.cloud/backend/public/api/healthcareproviders', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newProvider),
        });
      }
      // Refresh the list of providers after creating or updating
      fetchProviders();
      // Clear the form fields
      setNewProvider({
        UserID: null,
        Username: '',
        Password: '',
        Email: '',
        Address: '',
        Phone: '',
        Specialization: '',
        LicenceNumber: '',
        Education: '',
        ExperienceYears: '',
        Status: 'Active',
        DateOfBirth: '1999-01-01',
        Role: 'Healthcare Provider'
      });
    } catch (error) {
      console.error('Error creating or updating provider:', error);
    }
  };

  const deleteProvider = async (userID) => {
    try {
      const response = await fetch(`https://sxg3378.uta.cloud/backend/public/api/delete_healthcareprovider/${userID}`, {
        method: 'GET',
      });
      if (response.ok) {
        fetchProviders(); 
      } else {
        console.error('Failed to delete provider:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting provider:', error);
    }
  };

  const editProvider = (provider) => {
    setNewProvider({
      UserID: provider.UserID,
      Username: provider.Username,
      Password: provider.Password,
      Email: provider.Email,
      Address: provider.Address,
      Phone: provider.Phone,
      Specialization: provider.Specialization,
      LicenceNumber: provider.LicenceNumber,
      Education: provider.Education,
      ExperienceYears: provider.ExperienceYears,
      Status: provider.Status,
      DateOfBirth: provider.DateOfBirth,
      Role: provider.Role
    });
  };

  return (
    <>
      <AdministratorHeader />
      <div>
        <h1 style={{ textAlign: "center" }}>Healthcare Provider Management</h1>
        <div className='container' style={{ border: "1px solid GrayText", borderRadius: "5px", padding: "25px", color: "" }}>
          <h2>Create New Provider</h2>
          <input style={{marginBottom:"5px",padding:"5px"}}
            type="text"
            name="Username"
            value={newProvider.Username}
            onChange={handleInputChange}
            placeholder="Username"
          />
          <input style={{marginBottom:"5px",padding:"5px"}}
            type="password"
            name="Password"
            value={newProvider.Password}
            onChange={handleInputChange}
            placeholder="Password"
          />
          <input style={{marginBottom:"5px",padding:"5px"}}
            type="email"
            name="Email"
            value={newProvider.Email}
            onChange={handleInputChange}
            placeholder="Email"
          />
          <input style={{marginBottom:"5px",padding:"5px"}}
            type="text"
            name="Address"
            value={newProvider.Address}
            onChange={handleInputChange}
            placeholder="Address"
          />
          <input style={{marginBottom:"5px",padding:"5px"}}
            type="text"
            name="Phone"
            value={newProvider.Phone}
            onChange={handleInputChange}
            placeholder="Phone"
          />
          <input style={{marginBottom:"5px",padding:"5px"}}
            type="text"
            name="Specialization"
            value={newProvider.Specialization}
            onChange={handleInputChange}
            placeholder="Specialization"
          />
          <input style={{marginBottom:"5px",padding:"5px"}}
            type="text"
            name="LicenceNumber"
            value={newProvider.LicenceNumber}
            onChange={handleInputChange}
            placeholder="Licence Number"
          />
          <input style={{marginBottom:"5px",padding:"5px"}}
            type="text"
            name="Education"
            value={newProvider.Education}
            onChange={handleInputChange}
            placeholder="Education"
          />
          <input style={{marginBottom:"5px",padding:"5px"}}
            type="text"
            name="ExperienceYears"
            value={newProvider.ExperienceYears}
            onChange={handleInputChange}
            placeholder="Experience Years"
          />
          <button className="button" style={{width:"100%"}} onClick={createOrUpdateProvider}>Create Provider</button>
          <br />
          <h2>Healthcare Providers</h2>
          <table style={{ marginTop: "25px" }}>
            <thead>
              <tr>
                <th>Username</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {providers.map(provider => (
                <tr key={provider.UserID}>
                  <td>{provider.Username}</td>
                  <td>
                    <button onClick={() => editProvider(provider)} style={{background:"green",color:"white",padding:"10px",width:"10%",marginRight:"10px"}}>Edit</button>
                    <button onClick={() => deleteProvider(provider.UserID)} style={{background:"red",border:"1px solid red",color:"white",padding:"10px",width:"25%"}}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default HealthcareProviderManagement;
